import {acquireAccessToken, getActiveAccount} from "../index";
import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {Banner} from "../components/banner/Banner";
import {
	Order,
	OrderCreationResponse,
	OrderEditCartDetails,
	OrderProductUpdateRequest,
	OrderTracking,
	OrderTrackingFetch,
	OrderVm
} from "../components/order/Order";
import AuthenticationResponse from "../components/user/login/AuthenticationResponse";
import AuthenticationRequest from "../components/user/login/AuthenticationRequest";
import {RootState} from "./store";
import {Site} from "../components/site/Site";
import {OrderStatus} from "../components/order-status/OrderStatus";
import {ConsumerSearchCriteria} from "../components/admin/customer/ConsumerSearchCriteria";
import {State} from "../components/state/State";
import {AddressInputVm, AddressVm} from "../components/input/address/Address";
import SaveAddressRequest from "../components/user/addresses/userAddresses/addUserAddress/SaveAddressRequest";
import {ProductSearchCriteria} from "../components/admin/product/ProductSearchCriteria";
import {GetAccessoryProductRequest, ProductType, SiteProduct} from "../components/product/Product";
import {Image} from "../components/image/Image";
import {BulletPoint} from "../components/product/BulletPoint";
import {SiteProductVariant} from "../components/product/SiteProductVariant";
import {Category, SiteCategory} from "../components/category/Category";
import {SiteProductVariantQuantityOption} from "../components/product/SiteProductVariantQuantityOption";
import {Component} from "../components/input/component/Component";
import {
	BankConfiguration,
	BankConfigurationValidationResponse,
	Configuration,
	ConfigurationFetchOptions,
	SelectedConfigurationsRequest
} from "../components/input/configuration/Configuration";
import {SiteCard} from "../components/siteCard/SiteCard";
import {CardItem} from "../components/siteCard/cardItem/CardItem";
import {AddToCartRequest} from "../components/cart/AddToCartModels";
import {AddCartProductResponse, CartProductVM} from "../components/cart/CartProduct";
import {ProductInputValue, ValidateInputRequest} from "../components/input/ProductInputValue";
import {UserHistory} from "../components/admin/admin/UserHistory";
import {SaveOrderVm} from "../components/admin/order/SaveOrderVm";
import {CartPricing, CartPricingRequest, SiteProductVariantPricing} from "../components/cart/CartPricing";
import {ShippingOption} from "../components/shipping-options/ShippingOption";
import {PrintingSoftware} from "../components/input/printingSoftware/PrintingSoftware";
import {PaymentMethod, PaymentMethodsRequest} from "../components/payment/PaymentMethod";
import {ShippingOptionsRequest} from "../components/shipping-options/ShippingOptionsRequest";
import {
	DeletePaymentDetailsRequest,
	PaymentDetails,
	PaymentInformation,
	SavePaymentDetailsRequest
} from "../components/payment/PaymentDetails";
import {SavedPaymentDetails} from "../components/payment/SavedPaymentDetails";
import {Admin, Role} from "../components/user/Admin";
import {AdminSearchCriteria} from "../components/admin/admin/AdminSearchCriteria";
import {Consumer, ConsumerDetails} from "../components/admin/customer/Consumer";
import {
	FinancialInstitution,
	SaveFinancialInstitutionRequest
} from "../components/admin/financialInstitution/FinancialInstitution";
import {MicrFormat, MicrFormatOrderResponse, SaveMicrFormatRequest} from "../components/admin/micr/MicrFormat";
import SignUpRequest from "../components/user/signUp/SignUpRequest";
import SignUpResponse from "../components/user/signUp/SignUpResponse";
import {Claim} from "../components/admin/Claim";
import {OrderProductVm} from "../components/order/OrderProductVm";
import {
	MicrBankInformation,
	PreviewMicrFormatRequest,
	PreviewMicrResponse
} from "../components/admin/micr/PreviewMicrFormatRequest";
import {OrderShippingInformation} from "../components/admin/order/tabs/details/OrderShippingInformation";
import {OrderPricing} from "../components/order/OrderPricing";
import {OrderPayment} from "../components/admin/order/tabs/details/OrderPayment";
import {FailedPayment} from "../components/admin/order/tabs/details/FailedPayment";
import {UpdateOrderVm} from "../components/order/UpdateOrderVm";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {BrowserStorageType, fetchStorageValue} from "../utils/Utils";
import {clearSession, setCredentials} from "../components/user/login/AuthenticationSlice";
import {Mutex} from "async-mutex";
import {SaveUserProfileDetailsRequest} from "../components/user/profile/details/UserProfileDetailsEdit";
import {ConsumerSubscribeRequest} from "../components/user/profile/details/ConsumerSubscribeRequest";
import {Reason} from "../components/order-status/Reason";
import {GetPreviewRequest} from "../components/check/GetPreviewRequest";
import {CartIdType} from "../components/cart/CartSlice";
import {SubscribeResponse} from "../components/footer/subscribe/SubscribeResponse";
import SubscribeRequest from "../components/footer/subscribe/SubscribeRequest";
import {
	Refund,
	RefundApproveDenyRequest,
	RefundCategory,
	RefundRequest,
	RefundType
} from "../components/admin/order/tabs/refund/Refund";
import {OrderHistory} from "../components/admin/order/tabs/history/OrderHistory";
import {
	HoldFlagReason,
	HoldFlagStatus,
	HoldFlagUpdateInfo,
	NewHoldFlagRequest
} from "../components/admin/holdsQueue/HoldsFlag";
import {Note, NoteCategory} from "../components/admin/note/Note";
import {FinancialInstitutionHistory} from "../components/admin/financialInstitution/FinancialInstitutionHistory";
import {MicrFormatHistory} from "../components/admin/micr/MicrFormatHistory";
import {ACHRejectionCode} from "../components/payment/achRejection/ACHRejectionCode";
import {NewACHRejectionRequest} from "../components/payment/achRejection/NewACHRejectionRequest";
import {ValidResetPasswordTokenResponse} from "../components/user/resetPassword/ValidResetPasswordTokenResponse";
import ResetPasswordRequest from "../components/user/resetPassword/ResetPasswordRequest";
import {ValidateResetPasswordTokenRequest} from "../components/user/resetPassword/ValidateResetPasswordTokenRequest";
import {AddNotesRequest} from "../components/admin/order/tabs/details/AddNotesRequest";
import {RelatedOrder} from "../components/admin/order/tabs/related/RelatedOrder";
import {PaymentMethodRollup} from "../components/reports/payment-method-rollup/PaymentMethodRollup";
import {OrderShipmentVm} from "../components/order/OrderShipment.model";
import GetConsumerAddressByIdRequest
	from "../components/user/addresses/userAddresses/addUserAddress/GetConsumerAddressByIdRequest";
import {DeleteAddressRequest} from "../components/user/addresses/userAddresses/addUserAddress/DeleteAddressRequest";
import {
	ReprintOrderProductRequest
} from "../components/admin/order/tabs/details/orderProduct/unitDetail/ReprintOrderProductRequest";
import {UpdateOrderProductRequest} from "../components/admin/order/tabs/details/UpdateOrderProductRequest";
import {OrderAchReject} from "../components/reports/order-ach-reject/OrderAchReject";
import {OrderItemDownload} from "../components/reports/order-item-download/OrderItemDownload";
import {OrderStatusReport} from "../components/reports/order-status-report/OrderStatusReport";
import {CustomerEmailDownload} from "../components/reports/customer-email-download/CustomerEmailDownload";
import {OrderDailySales} from "../components/reports/order-daily-sales/OrderDailySales";
import {OrderProductMix} from "../components/reports/order-product-mix/OrderProductMix";
import {DiscountType, SaveDiscountRequest} from "../components/discount/Discount";
import {ControlListGroup} from "../components/admin/controlList/ControlListGroup";
import {ControlListAction} from "../components/admin/controlList/ControlListAction";
import {ControlListValueSaveRequest} from "../components/admin/controlList/ControlListValue";
import {ControlListReason} from "../components/admin/controlList/ControlListReason";
import {OrderMonthEnd} from "../components/reports/order-month-end/OrderMonthEnd";
import {Ltv} from "../components/reports/ltv/Ltv";
import {ConsumerControlListValuesVm} from "../components/admin/controlList/ConsumerControlListValuesVm";
import {OrderRefundReport} from "../components/reports/order-refund-report/OrderRefundReport";
import {SourceCodeSales} from "../components/reports/source-code-sales/SourceCodeSales";
import {ValidateTrackingResponse} from "../components/order/tracking/ValidateTrackingResponse";
import {OrderCreationValidation, OrderCreationValidationRequest} from "../components/order/OrderCreationValidation";
import {VendorOrderProductStatusOverview} from "../components/admin/vendor/VendorOrderProductStatusOverview";
import {UpdateCustomLogoRequest, WoodcutImage} from "../components/image/custom-logo/WoodcutImage";
import {TransactionStatus} from "../components/payment/TransactionStatus";
import {OrderProductPaymentRefund} from "../components/admin/order/tabs/refund/OrderProductPaymentRefund";
import {LaserCheckOverlay} from "../components/image/custom-overlay/LaserCheckOverlay";
import {UploadCustomOverlayRequest} from "../components/image/custom-overlay/UploadCustomOverlayRequest";
import {ForgotPasswordRequest} from "../components/user/login/loginForm/ForgotPasswordRequest";
import axios from "axios";
import {TaxReport} from "../components/reports/tax-report/TaxReport";
import {TransactionReport} from "../components/reports/transaction-report/TransactionReport";
import {RoyaltiesFees} from "../components/reports/royalties-fees/RoyaltiesFees";
import ResetTokenRequest from "../components/user/resetPassword/ResetTokenRequest";
import {AspectRatio} from "../components/image/AspectRatio"
import {ReportParams} from "../components/reports/ReportParams";
import {InRangeRequestParams, TaxFile, TaxItem} from "../components/tax-processing/TaxItem";

export enum TagTypes {
	Cart = 'Cart',
	User = 'User',
	Order = 'Order',
	PaymentDetails = 'PaymentDetails',
	Claims = 'Claims',
	Micr = 'Micr',
	FinancialInstitutions = 'FinancialInstitutions',
	Consumer = 'Consumer',
	Subscribed = 'Subscribed',
	Refund = 'Refund',
	HoldFlag = 'HoldFlag',
	Payment = 'Payment',
	ControlList = 'ControlList',
	TaxProcessing = 'TaxProcessing',
}

export const isUsingAdminSite = () => {
	return window.location.pathname.toLowerCase().includes('admin');
}

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
	baseUrl: '/api',
	prepareHeaders: async (headers, { getState }) => {

		const clientToken = (getState() as RootState).authentication.token;
		let token: any = null;

		if(!isUsingAdminSite() && clientToken) {
			token = clientToken;
		} else if (isUsingAdminSite() && getActiveAccount()) {
			token = await acquireAccessToken();
		}

		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		}
		headers.set('Content-Type', 'application/json')
		return headers;
	},
});

const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	// Use mutex to lock to multiple refresh request don't happen at once
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions)
	if (result.error && result.error.status === 401 && !isUsingAdminSite()) {
		if(!mutex.isLocked()) {
			const release = await mutex.acquire()
			try {
				const refreshToken = fetchStorageValue("ProductCatalogRefreshToken-value", BrowserStorageType.LocalStorage);
				const accessToken = fetchStorageValue("ProductCatalogAuthToken-value", BrowserStorageType.LocalStorage);
				if (!refreshToken || !accessToken)
					return result;
				// try to get a new token
				const refreshResult = await axios.post('/api/Consumers/RefreshToken',
					{refreshToken, accessToken},
					{ headers: {authorization: `Bearer ${accessToken}`}});
				if (refreshResult.data) {
					// store the new token
					api.dispatch(setCredentials(refreshResult.data))
					// retry the initial query
					result = await baseQuery(args, api, extraOptions)
				} else {
					api.dispatch(clearSession());
					api.dispatch(apiSlice.util.resetApiState());
				}

			} catch(e: any) {
				api.dispatch(clearSession());
				api.dispatch(apiSlice.util.resetApiState());
			} finally {
				// Release mutex lock
				release();
			}
		} else {
			// wait until the mutex is available without locking it
			await mutex.waitForUnlock()
			result = await baseQuery(args, api, extraOptions)
		}
	}
	if (result.error && result.error.status === 401 && isUsingAdminSite()) {
		console.error('401 RESPONSE WHILE USING ADMIN SITE')
		console.error(result);
	}
	return result
}

export const apiSlice = createApi({

	baseQuery: baseQueryWithReauth,

	tagTypes: Object.keys(TagTypes), // use these to invalidate rtk-query's caches

	endpoints: builder => {
		const getReportQuery = <T>(typeName: string) => {
			return builder.query<T, ReportParams>({
				query: params => ({
					url: `/Reports/${typeName}`,
					params,
				}),
				providesTags: [TagTypes.Order],
			})
		}

		return {
			getCurrentCartCheckAddress: builder.query<AddressInputVm, void>({
				query: () => ({
					url: '/Carts/Address'
				}),
				providesTags: [TagTypes.Cart, TagTypes.Order]
			}),
			getOrderShipmentsByOrder: builder.query<OrderShipmentVm[], number>({
				query: (orderId) => `/OrderShipments/Order/${orderId}`
			}),
			getPaymentMethods: builder.query<PaymentMethod[], void>({
				query: () => `/Payments/Methods`
			}),
			getCartOrderPaymentMethods: builder.query<PaymentMethod[], PaymentMethodsRequest>({
				query: (request) => request.cartId ? `/Payments/Methods/Cart/${request.cartId}` : `/Payments/Methods/Order/${request.orderId}`,
				providesTags: [TagTypes.Cart, TagTypes.Order]
			}),
			getPreviousOrderPaymentInformation: builder.query<PaymentInformation, number>({
				query: (orderId) => `/Payments/Information/Order/${orderId}`,
				providesTags: [TagTypes.Cart, TagTypes.Order]
			}),
			getSavedPaymentDetails: builder.query<SavedPaymentDetails[], void>({
				query: () => '/Payments/Saved',
				providesTags: [TagTypes.PaymentDetails]
			}),
			getSavedPaymentDetailsForUser: builder.query<SavedPaymentDetails[], number>({
				query: (userId) => `/Payments/${userId}/Saved`,
				providesTags: [TagTypes.PaymentDetails],
			}),
			savePaymentDetails: builder.mutation<string, SavePaymentDetailsRequest>({
				query: (paymentDetailsRequest) => ({
					url: "/Payments/Details",
					method: "POST",
					body: paymentDetailsRequest
				}),
				invalidatesTags: [TagTypes.PaymentDetails]
			}),
			updateSavedPaymentDetails: builder.mutation<PaymentDetails[], SavePaymentDetailsRequest>({
				query: (savePaymentDetailsRequest) => ({
					url: `/Payments/Saved/${savePaymentDetailsRequest.customerPaymentProfileId}`,
					method: "PUT",
					body: savePaymentDetailsRequest
				}),
				invalidatesTags: [TagTypes.PaymentDetails]
			}),
			deleteSavedPaymentDetails: builder.mutation<PaymentDetails[], string>({
				query: (customerPaymentProfileId) => ({
					url: `/Payments/Saved/${customerPaymentProfileId}`,
					method: "DELETE"
				}),
				invalidatesTags: [TagTypes.PaymentDetails]
			}),
			deleteSavedPaymentDetailsForUser: builder.mutation<PaymentDetails[], DeletePaymentDetailsRequest>({
				query: (deletePaymentDetailsRequest) => ({
					url: `/Payments/${deletePaymentDetailsRequest.userId}/Saved/${deletePaymentDetailsRequest.customerPaymentProfileId}`,
					method: "DELETE"
				}),
				invalidatesTags: [TagTypes.PaymentDetails]
			}),
			getSiteCards: builder.query<SiteCard[], void>({
				query: siteId => `/SiteCards`
			}),
			getSiteCardItems: builder.query<CardItem[], number>({
				query: siteCardId => `/SiteCards/${siteCardId}/CardItems`
			}),
			getOrderOverview: builder.query<Order, number>({
				query: orderId => `/Orders/${orderId}`,
				providesTags: [TagTypes.Order]
			}),
			getBannersByType: builder.query<Banner[], number>({
				query: bannerTypeId => `/Banners/Type/${bannerTypeId}`,
			}),
			getBulletPointsById: builder.query<BulletPoint[], number>({
				query: productId => `/BulletPoint/Type/${productId}`,
			}),
			getCurrentUsersOrders: builder.query<OrderVm[], void>({
				query: () => '/Orders/CurrentUser',
				providesTags: [TagTypes.User, TagTypes.Order],
			}),
			getOrderStatuses: builder.query<OrderStatus[], void>({
				query: () => '/OrderStatuses',
				providesTags: [TagTypes.Order]
			}),
			getAvailableOrderStatuses: builder.query<OrderStatus[], number>({
				query: orderId => `/OrderStatuses/${orderId}`,
				providesTags: [TagTypes.Order],
			}),
			getOrderStatusReasons: builder.query<Reason[], number>({
				query: statusId => `/OrderStatuses/Reasons/${statusId}`,
			}),
			authenticate: builder.mutation<AuthenticationResponse, AuthenticationRequest>({
				query: (credentials) => ({
					url: "/Consumers/Login",
					method: "POST",
					body: credentials
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			getSites: builder.query<Site[], void>({
				query: () => '/Sites'
			}),
			getConsumerDetailsById: builder.query<ConsumerDetails, number>({
				query: consumerId => ({url: `/Consumers/${consumerId}`}),
				providesTags: [TagTypes.Consumer],
			}),
			getCustomers: builder.query<Consumer[], ConsumerSearchCriteria>({
				query: params => ({url: '/Consumers/Search', params}),
				providesTags: [TagTypes.Consumer],
			}),
			updateConsumer: builder.mutation<number, Consumer>({
				query: consumer => ({
					url: '/Consumers',
					method: 'PUT',
					body: consumer,
				}),
				invalidatesTags: [TagTypes.User, TagTypes.Consumer],
			}),
			getStates: builder.query<State[], void>({
				query: () => '/States'
			}),
			getImages: builder.query<Image[], void>({
				query: () => '/Images'
			}),
			getAspectRatios: builder.query<AspectRatio[], void>({
				query: () => '/Images/AspectRatios'
			}),
			getImageHost: builder.query<string, void>({
				query: () => ({
					url: 'Images/Host',
					responseHandler: 'text',
				}),
			}),
			getCustomLogo: builder.query<WoodcutImage | undefined, { orderId?: number, cartId?: CartIdType }>({
				query: params => ({
					url: '/Images/CustomLogo',
					params,
				}),
				providesTags: [TagTypes.Order, TagTypes.Cart],
			}),
			updateCustomLogo: builder.mutation<number, UpdateCustomLogoRequest>({
				query: body => ({
					url: '/Images/CustomLogo',
					method: 'POST',
					body,
				}),
				invalidatesTags: [TagTypes.Order, TagTypes.Cart]
			}),
			getCustomOverlay: builder.query<LaserCheckOverlay | undefined, { orderId?: number, cartId?: CartIdType }>({
				query: params => ({
					url: '/Images/CustomOverlay',
					params,
				}),
				providesTags: [TagTypes.Order, TagTypes.Cart],
			}),
			uploadCustomOverlay: builder.mutation<void, UploadCustomOverlayRequest>({
				query: body => ({
					url: '/Images/CustomOverlay',
					method: 'POST',
					body
				}),
				invalidatesTags: [TagTypes.Order, TagTypes.Cart]
			}),
			getAddressesByConsumerId: builder.query<AddressVm[], number>({
				query: consumerId => `/Addresses/Consumer/${consumerId}`,
				providesTags: [TagTypes.Consumer]
			}),
			getBillingAddressByOrderId: builder.query<AddressVm | undefined, number>({
				query: orderId => `/Addresses/Order/${orderId}`,
				providesTags: [TagTypes.Order]
			}),
			getConsumerAddressByAddressId: builder.query<AddressVm, GetConsumerAddressByIdRequest>({
				query: request => `/Addresses/${request.addressId}`,
				providesTags: [TagTypes.Consumer]
			}),
			saveAddress: builder.mutation<number, SaveAddressRequest>({
				query: request => ({
					url: `/Addresses/Consumer`,
					method: 'POST',
					body: request
				}),
				invalidatesTags: [TagTypes.Consumer]
			}),
			updateAddress: builder.mutation<number, SaveAddressRequest>({
				query: request => ({
					url: `/Addresses/Consumer/${request.addressId}`,
					method: 'PUT',
					body: request
				}),
				invalidatesTags: [TagTypes.Consumer]
			}),
			deleteAddress: builder.mutation<number, DeleteAddressRequest>({
				query: deleteRequest => ({
					url: `/Addresses/Consumer/${deleteRequest.addressId}`,
					method: 'DELETE',
					body: deleteRequest
				}),
				invalidatesTags: [TagTypes.Consumer]
			}),
			getSiteProducts: builder.query<SiteProduct[], ProductSearchCriteria>({
				query: params => ({url: '/Products', params})
			}),
			getProduct: builder.query<SiteProduct, number>({
				query: siteProductId => `/Products/${siteProductId}`
			}),
			getSiteProductByUrlName: builder.query<SiteProduct, { siteProductUrlName: string, siteId?: number }>({
				query: params => ({
					url: `/Products/UrlName/${params.siteProductUrlName}`,
					params: {
						siteId: params.siteId
					}
				}),
			}),
			getSiteProductVariantsBySiteProductId: builder.query<SiteProductVariant[], number>({
				query: siteProductId => `/Products/${siteProductId}/Variants`
			}),
			getAllCategories: builder.query<Category[], void>({
				query: () => '/SiteCategories'
			}),
			getSiteCategoryByUrlNameOrId: builder.query<SiteCategory, string | number>({
				query: urlNameOrId => `/SiteCategories/${urlNameOrId}`
			}),
			getParentSiteCategories: builder.query<SiteCategory[], number>({
				query: siteCategoryId => `/SiteCategories/${siteCategoryId}/ParentSiteCategories`
			}),
			getChildSiteCategories: builder.query<SiteCategory[], number>({
				query: siteCategoryId => `/SiteCategories/${siteCategoryId}/ChildSiteCategories`
			}),
			getCategoryProducts: builder.query<SiteProduct[], number>({
				query: categoryId => `/SiteCategories/${categoryId}/Products`
			}),
			getSiteProductVariantById: builder.query<SiteProductVariant, number>({
				query: siteProductVariantId => `/Products/Variants/${siteProductVariantId}`
			}),
			getSiteProductVariantQuantityOptions: builder.query<SiteProductVariantQuantityOption[], number>({
				query: siteProductVariantId => `/Products/Variants/${siteProductVariantId}/SiteProductVariantQuantityOptions`
			}),
			getSiteProductVariantQuantityOption: builder.query<SiteProductVariantQuantityOption, number>({
				query: siteProductVariantQuantityOptionId => `/SiteProductVariantQuantityOptions/${siteProductVariantQuantityOptionId}`
			}),
			getSiteProductVariantWithConfigurationsPricing: builder.query<SiteProductVariantPricing, SelectedConfigurationsRequest>({
				query: selectedConfigurations => ({
					url: `/Products/Variants/${selectedConfigurations.siteProductVariantQuantityOptionId}/Pricing`,
					method: "POST",
					body: selectedConfigurations
				})
			}),
			getComponentsForSiteProduct: builder.query<Component[], number>({
				query: siteProductId => ({url: '/Components', params: {siteProductId}})
			}),
			getConfigurationsForSiteProduct: builder.query<Configuration[], ConfigurationFetchOptions>({
				query: params => ({url: '/Configurations', params})
			}),
			getComponentsForVariant: builder.query<Component[], number>({
				query: productVariantId => `/Components/Variants/${productVariantId}`
			}),
			subscribe: builder.mutation<SubscribeResponse, SubscribeRequest>({
				query: email => ({
					url: "/Email/Subscribe",
					method: "POST",
					body: email
				}),
				invalidatesTags: [TagTypes.Subscribed]
			}),
			subscribeCurrentUser: builder.mutation<SubscribeResponse, ConsumerSubscribeRequest>({
				query: request => ({
					url: "/Consumers/Subscribe",
					method: "POST",
					body: request
				}),
				invalidatesTags: [TagTypes.Subscribed]
			}),
			isUserSubscribed: builder.query<boolean, void>({
				query: () => '/Consumers/Subscribe',
				providesTags: [TagTypes.Subscribed]
			}),
			getProductPreview: builder.query<string | undefined, GetPreviewRequest | number>({
				query: requestParam => ({
					url: (typeof requestParam === 'number') ? `/OrderProducts/${requestParam}/Preview` : '/CartProducts/Preview',
					responseHandler: "text",
					method: 'POST',
					body: (typeof requestParam === 'object') ? requestParam : undefined,
				}),
				providesTags: [TagTypes.Micr, TagTypes.Order, TagTypes.Cart]
			}),
			getCartExists: builder.query<boolean, CartIdType>({
				query: cartId => `/Carts/${cartId}/Exists`,
				providesTags: [TagTypes.Cart]
			}),
			signInCartCheck: builder.mutation<void, AuthenticationRequest>({
				query: authRequest => ({
					url: '/Consumers/SignInCartCheck',
					method: 'POST',
					body: authRequest
				})
			}),
			getCartProducts: builder.query<CartProductVM[], CartIdType>({
				query: cartId => cartId ? `/CartProducts?CartId=${cartId}` : '/CartProducts',
				providesTags: [TagTypes.Cart]
			}),
			getCartProductInputValues: builder.query<ProductInputValue[], number> ({
				query: cartProductId => ({ url: `/CartProducts/${cartProductId}/InputValues` }),
				providesTags: [TagTypes.Cart]
			}),
			getOrderProductInputValues: builder.query<ProductInputValue[], number> ({
				query: orderProductId => ({ url: `/OrderProducts/${orderProductId}/InputValues` }),
				providesTags: [TagTypes.Order]
			}),
			getCartProductsPricing: builder.query<CartPricing, CartPricingRequest | undefined>({
				query: cartPricingRequest => ({
					url: '/CartProducts/Pricing',
					method: 'POST',
					body: cartPricingRequest
				}),
				providesTags: [TagTypes.Cart]
			}),
			removeAllProductsFromCart: builder.mutation<number, CartIdType>({
				query: cartId => ({
					url: `/CartProducts/RemoveAll/${cartId}`,
					method: 'DELETE'
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			addProductToCart: builder.mutation<AddCartProductResponse, AddToCartRequest>({
				query: product => ({
					url: '/CartProducts',
					method: 'POST',
					body: product
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			removeProductFromCart: builder.mutation<number, number>({
				query: cartProductId => ({
					url: `/CartProducts/${cartProductId}`,
					method: 'DELETE'
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			createCartCheckoutEvent: builder.mutation<void, string>({
				query: cartId => ({
					url: `/Carts/${cartId}/Event/Checkout`,
					method: 'POST'
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			contact: builder.mutation({
				query: contact => ({
					url: "/Email/Contact",
					method: "POST",
					body: contact
				})
			}),
			validateBankingUserInput: builder.mutation<BankConfigurationValidationResponse, BankConfiguration>({
				query: (requestData) => ({
					url: `/Configurations/ValidateBankingConfiguration`,
					method: 'POST',
					body: requestData
				})
			}),
			validateUserInput: builder.mutation<boolean, ValidateInputRequest>({
				query: (requestData) => ({
					url: `/Configurations/${requestData.configurationId}/Validate`,
					method: 'POST',
					body: '"' + requestData.stringValue + '"'
				})
			}),
			getAdmins: builder.query<Admin[], AdminSearchCriteria | undefined>({
				query: params => ({url: '/Admins', params}),
				providesTags: [TagTypes.User],
			}),
			getRoles: builder.query<Role[], void>({
				query: () => '/Admins/Roles',
			}),
			getClaims: builder.query<Claim[], void>({
				query: () => '/Admins/Claims',
				providesTags: [TagTypes.Claims],
			}),
			saveAdmin: builder.mutation<number, Admin>({
				query: admin => ({
					url: '/Admins',
					method: 'POST',
					body: admin,
				}),
				invalidatesTags: [TagTypes.User, TagTypes.Claims],
			}),
			sendOrderConfirmationEmail: builder.mutation({
				query: orderId => ({
					url: `Orders/SendOrderConfirmation/${orderId}`
				})
			}),
			sendOrderTrackingEmail: builder.mutation({
				query: orderId => ({
					url: `Orders/SendTracking/${orderId}`
				})
			}),
			getCurrentConsumerDetails: builder.query<Consumer, void>({
				query: () => `/Consumers/Current`,
				providesTags: [TagTypes.Consumer, TagTypes.Cart],
			}),
			getConsumersOrders: builder.query<Order[], number>({
				query: (consumerId) => `/Orders/Consumer/${consumerId}`,
				providesTags: [TagTypes.Order],
			}),
			getUserHistory: builder.query<UserHistory[], number>({
				query: userId => `/Users/${userId}/History`,
				providesTags: [TagTypes.User],
			}),
			createOrder: builder.mutation<OrderCreationResponse, SaveOrderVm>({
				query: body => ({
					url: '/Orders',
					method: 'POST',
					body,
				}),
				invalidatesTags: [TagTypes.Order, TagTypes.Cart],
			}),
			updateOrderByCartId: builder.mutation<void, SaveOrderVm>({
				query: (body) => ({
					url: `/Orders`,
					method: 'PATCH',
					body,
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			updateOrder: builder.mutation<void, [number, UpdateOrderVm]>({
				query: ([orderId, body]) => ({
					url: `/Orders/${orderId}`,
					method: 'PATCH',
					body,
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			migrateOrderProductsToCart: builder.mutation<void, OrderEditCartDetails>({
				query: (orderEditCartDetails) => ({
					url: `/Orders/${orderEditCartDetails.orderId}/Cart/${orderEditCartDetails.cartId}`,
					method: 'PATCH'
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			createOrderEditCart: builder.mutation<CartIdType, OrderProductUpdateRequest>({
				query: (body) => ({
					url: `/Orders/${body.orderId}/Cart`,
					method: 'POST',
					body,
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			reprintOrderProduct: builder.mutation<void, ReprintOrderProductRequest>({
				query: (reprintOrderProductRequest) => ({
					url: `/OrderProducts/Reprint`,
					method: 'POST',
					body: reprintOrderProductRequest
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			cancelOrderProductReprint: builder.mutation<void, number>({
				query: (orderProductReprintId) => ({
					url: `/OrderProducts/Reprint/${orderProductReprintId}`,
					method: 'DELETE'
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			fetchShippingOptions: builder.query<ShippingOption[], ShippingOptionsRequest>({
				query: body => ({
					url: '/ShippingOptions',
					method: 'POST',
					body,
				}),
				providesTags: [TagTypes.Cart]
			}),
			signUp: builder.mutation<SignUpResponse, SignUpRequest>({
				query: (signUpRequest) => ({
					url: "/Consumers/SignUp",
					method: "POST",
					body:  signUpRequest
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			sendForgotPasswordEmail: builder.mutation<void, ForgotPasswordRequest>({
				query: (forgotPasswordRequest) => ({
					url: "/Consumers/ForgotPassword",
					method: "POST",
					body: forgotPasswordRequest
				})
			}),
			getIsResetPasswordTokenValid: builder.query<ValidResetPasswordTokenResponse, ValidateResetPasswordTokenRequest>({
				query: (request) => ({
					url: "/Consumers/ValidateResetPasswordToken",
					method: "POST",
					body: request
				})
			}),
			setPassword: builder.mutation<void, ResetPasswordRequest>({
				query: (resetPasswordRequest) => ({
					url: "/Consumers/SetPassword",
					method: "POST",
					body: resetPasswordRequest
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			requestNewResetTokenLink: builder.mutation<void, ResetTokenRequest>({
				query: (resetTokenRequest) => ({
					url: "/Consumers/RequestResetToken",
					method: "POST",
					body: resetTokenRequest
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			sendPasswordSetupEmail: builder.mutation<void, number>({
				query: (consumerId) => ({
					url: `/Consumers/${consumerId}/SendPasswordSetupEmail`,
					method: "POST"
				}),
				invalidatesTags: [TagTypes.Cart]
			}),
			getPrintingSoftware: builder.query<PrintingSoftware[], { consumerId?: number, numSignatureLines: number}>({
				query: params => ({ url: '/PrintingSoftware', params }),
			}),
			getSiteProductForAccessory: builder.query<SiteProduct, GetAccessoryProductRequest>({
				query: accessoryProductRequest => `/Products/Site/${accessoryProductRequest.siteProductId}/Accessory/${accessoryProductRequest.accessoryProductId}`
			}),
			getFinancialInstitutions: builder.query<FinancialInstitution[], void>({
				query: () => `/FinancialInstitutions?includeAll=true`,
				providesTags: [TagTypes.FinancialInstitutions]
			}),
			getFinancialInstitutionById: builder.query<FinancialInstitution, number>({
				query: id => `/FinancialInstitutions/${id}`,
				providesTags: [TagTypes.FinancialInstitutions]
			}),
			getFinancialInstitutionByAba: builder.query<FinancialInstitution, string>({
				query: aba => `/FinancialInstitutions/Aba/${aba}`,
				providesTags: [TagTypes.FinancialInstitutions],
			}),
			saveFinancialInstitution: builder.mutation<number, SaveFinancialInstitutionRequest>({
				query: (financialInstitutionRequest) => ({
					url: "/FinancialInstitutions",
					method: "POST",
					body: financialInstitutionRequest
				}),
				invalidatesTags: [TagTypes.FinancialInstitutions, TagTypes.Micr]
			}),
			getMicrFormats: builder.query<MicrFormat[], number>({
				query: financialInstitutionId => ({url: `/FinancialInstitutions/${financialInstitutionId}/MicrFormats`}),
				providesTags: [TagTypes.Micr]
			}),
			getMicrFormatById: builder.query<MicrFormat, number>({
				query: micrFormatId => ({url: `/FinancialInstitutions/MicrFormats/${micrFormatId}`}),
				providesTags: [TagTypes.Micr]
			}),
			saveMicrFormat: builder.mutation<number, SaveMicrFormatRequest>({
				query: (micrFormat) => ({
					url: "/FinancialInstitutions/MicrFormats",
					method: "POST",
					body: micrFormat
				}),
				invalidatesTags: [TagTypes.Micr, TagTypes.Order]
			}),
			previewMicrFormat: builder.query<PreviewMicrResponse, PreviewMicrFormatRequest>({
				query: (previewMicrFormatRequest) => ({
					url: "/FinancialInstitutions/MicrFormats/Preview",
					method: "POST",
					body: previewMicrFormatRequest
				})
			}),
			getMicrCheckProductTypes: builder.query<ProductType[], void>({
				query: () => '/FinancialInstitutions/MicrFormats/CheckTypes'
			}),
			getFinancialInstitutionHistory: builder.query<FinancialInstitutionHistory[], number>({
				query: financialInstitutionId => `/FinancialInstitutions/${financialInstitutionId}/History`,
				providesTags: [TagTypes.FinancialInstitutions]
			}),
			getMicrFormatIdsByOrderId: builder.query<MicrFormatOrderResponse[], number>({
				query: orderId => ({url: `/Orders/${orderId}/MicrFormat`}),
				providesTags: [TagTypes.Micr],
			}),
			getOrderProductAccountNumberLengthById: builder.query<number, number>({
				query: orderProductId => ({url: `/OrderProducts/${orderProductId}/AccountNumberLength`}),
				providesTags: [TagTypes.Order],
			}),
			getMicrFormatHistory: builder.query<MicrFormatHistory[], number>({
				query: micrFormatId => `/FinancialInstitutions/MicrFormats/${micrFormatId}/History`,
				providesTags: [TagTypes.Micr]
			}),
			getBankInformationByOrderProoductId: builder.query<MicrBankInformation, number>({
				query: orderProductId => ({url: `Orders/OrderProduct/${orderProductId}/MicrBankInformation`}),
				providesTags: [TagTypes.Order]
			}),
			getFinancialInstitutionIdByOrderId: builder.query<number, number>({
				query: orderId => ({url: `/Orders/${orderId}/FinancialInstitution`}),
				providesTags: [TagTypes.Micr],
			}),
			checkOrderCreationValidation: builder.query<OrderCreationValidation, OrderCreationValidationRequest>({
				query: orderCreationValidationRequest => `/Orders/${orderCreationValidationRequest.orderId}/CreationValidation/${orderCreationValidationRequest.cartId ?? ""}`,
				providesTags: [TagTypes.Order],
			}),
			getVendorOrderProductStatus:  builder.query<VendorOrderProductStatusOverview, number>({
				query: orderId => `/Orders/${orderId}/VendorStatus`,
				providesTags: [TagTypes.Order],
			}),
			getOrderProducts: builder.query<OrderProductVm[], number>({
				query: orderId => `/OrderProducts/${orderId}`,
				providesTags: [TagTypes.Order],
			}),
			getOrderProductPricing: builder.query<SiteProductVariantPricing, number>({
				query: orderProductId => `/OrderProducts/${orderProductId}/Pricing`,
				providesTags: [TagTypes.Order],
			}),
			getOrderPricing: builder.query<OrderPricing, number>({
				query: orderId => `/Orders/${orderId}/Pricing`,
				providesTags: [TagTypes.Order],
			}),
			getOrderShippingInformation: builder.query<OrderShippingInformation, number>({
				query: orderId => `/Orders/${orderId}/ShippingInformation`,
				providesTags: [TagTypes.Order],
				transformResponse: (response: OrderShippingInformation, meta, arg) => {
					return {
						...response,
						repeatEmail: response.email,
					};
				}
			}),
			getOrderPaymentHistory: builder.query<OrderPayment[], number>({
				query: orderId => `/Payments/History/Order/${orderId}`,
				providesTags: [TagTypes.Order]
			}),
			getFailedPaymentsHistory: builder.query<FailedPayment[], number>({
				query: orderId => `/Payments/${orderId}/FailedPayments`,
				providesTags: [TagTypes.Order]
			}),
			getOrderPayments: builder.query<OrderPayment[], number>({
				query: orderId => `/Payments/Order/${orderId}`,
				providesTags: [TagTypes.Order]
			}),
			hasLastOrderPaymentFailed: builder.query<boolean, number>({
				query: orderId => `/Payments/Order/${orderId}/LastPaymentFailure`,
				providesTags: [TagTypes.Order],
			}),
			getOrderProductPayments: builder.query<OrderPayment[], number>({
				query: orderProductId => `/Payments/OrderProduct/${orderProductId}`,
				providesTags: [TagTypes.Order]
			}),
			getPaymentTransactionStatus: builder.query<TransactionStatus | null, number>({
				query: paymentId => `/Payments/${paymentId}/TransactionStatus`,
				providesTags: [TagTypes.Order]
			}),
			getOrderTrackingInformation: builder.query<OrderTracking[], OrderTrackingFetch>({
				query: params => ({url: '/Orders/Tracking', params}),
				providesTags: [TagTypes.Order],
			}),
			getOrderByCartId: builder.query<Order, string>({
				query: cartId => `/Orders/Cart/${cartId}`,
				providesTags: [TagTypes.Order],
			}),
			getAdminOrderTrackingInformation: builder.query<OrderTracking[], number>({
				query: orderId => `/Orders/Tracking/${orderId}`,
				providesTags: [TagTypes.Order],
			}),
			getConsumerOrderTrackingInformation: builder.query<OrderTracking[], void>({
				query: () => `/Orders/User/Tracking`
			}),
			getRecentOrderProducts: builder.query<SiteProduct[] | undefined, void>({
				query: () => '/OrderProducts/Recent',
				providesTags: [TagTypes.Order],
			}),
			updateOrderProduct: builder.mutation<void, UpdateOrderProductRequest>({
				query: (updateOrderProductRequest) => ({
					url: `/OrderProducts`,
					method: "PUT",
					body: updateOrderProductRequest
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			validateTrackingInformation: builder.mutation<ValidateTrackingResponse, OrderTrackingFetch>({
				query: (params) => ({
					url: '/Orders/ValidateTracking',
					method: 'POST',
					body: params
				})
			}),
			updateUserProfileDetails: builder.mutation<Consumer, SaveUserProfileDetailsRequest>({
				query: (profileDetails) => ({
					url: "/Consumers/ProfileDetails",
					method: "PUT",
					body: profileDetails
				}),
				invalidatesTags: [TagTypes.Consumer]
			}),
			getOrderRefunds: builder.query<Refund[], number | undefined>({
				query: (orderId) => orderId ? `Refunds/Order/${orderId}` : 'Refunds',
				providesTags: [TagTypes.Refund]
			}),
			getRefundTypes: builder.query<RefundType[], void>({
				query: () => '/Refunds/Types'
			}),
			getRefundCategories: builder.query<RefundCategory[], void>({
				query: () => '/Refunds/Categories'
			}),
			getRefundReasons: builder.query<Reason[], void>({
				query: () => '/Refunds/Reasons'
			}),
			getOrderProductPaymentRefundsByOrderId: builder.query<OrderProductPaymentRefund[], number>({
				query: (orderId) => `/Payments/OrderProductPaymentRefunds/Order/${orderId}`,
				providesTags: [TagTypes.Refund, TagTypes.Order]
			}),
			submitRefundRequest: builder.mutation<void, RefundRequest>({
				query: (refundRequest) => ({
					url: "/Refunds",
					method: "POST",
					body: refundRequest
				}),
				invalidatesTags: [TagTypes.Refund]
			}),
			approveDenyRefundRequest: builder.mutation<void, RefundApproveDenyRequest>({
				query: (refundApproveDenyRequest) => ({
					url: "/Refunds",
					method: "PUT",
					body: refundApproveDenyRequest
				}),
				invalidatesTags: [TagTypes.Refund, TagTypes.Order]
			}),
			getOrderHistory: builder.query<OrderHistory[], number>({
				query: orderId => `/Orders/${orderId}/History`,
				providesTags: [TagTypes.Order]
			})
			, getRelatedOrders: builder.query<RelatedOrder[], number>({
				query: orderId => `/Orders/${orderId}/RelatedOrders`,
				providesTags: [TagTypes.Order]
			}),
			saveDiscount:builder.mutation<void, SaveDiscountRequest>({
				query: (saveDiscountRequest) => ({
					url: `/Discounts`,
					method: "POST",
					body: saveDiscountRequest
				}),
				invalidatesTags: [TagTypes.Order, TagTypes.Cart]
			}),
			deleteDiscount: builder.mutation<void, number>({
				query: (discountId) => ({
					url: `/Discounts/${discountId}`,
					method: 'DELETE'
				}),
				invalidatesTags: [TagTypes.Order, TagTypes.Cart]
			}),
			getDiscountTypes: builder.query<DiscountType[], void>({
				query: () => '/Discounts/Types'
			}),
			getOrderNoteCategories: builder.query<NoteCategory[], void>({
				query: () => '/Orders/NoteCategories'
			}),
			getOrderNotes: builder.query<Note[], number>({
				query: orderId => `/Orders/${orderId}/Notes`,
				providesTags: [TagTypes.Order]
			}),
			addOrderNotes:builder.mutation<void, AddNotesRequest>({
				query: (addNotesRequest) => ({
					url: `/Orders/${addNotesRequest.orderId}/Notes`,
					method: "POST",
					body: addNotesRequest
				}),
				invalidatesTags: [TagTypes.Order]
			}),
			getHoldFlagReasons: builder.query<HoldFlagReason[], void>({
				query: () => `/HoldFlags/Reasons`,
				providesTags: [TagTypes.HoldFlag]
			}),
			getHoldFlagStatuses: builder.query<HoldFlagStatus[], void>({
				query: () => `/HoldFlags/Statuses`,
				providesTags: [TagTypes.HoldFlag]
			}),
			updateHoldFlag: builder.mutation<void, HoldFlagUpdateInfo>({
				query: (holdFlagUpdateInfo) => ({
					url: `/HoldFlags/${holdFlagUpdateInfo.holdFlagId}`,
					method: "PUT",
					body: holdFlagUpdateInfo
				}),
				invalidatesTags: [TagTypes.HoldFlag, TagTypes.Order]
			}),
			createHoldFlag: builder.mutation<void, NewHoldFlagRequest>({
				query: (newHoldFlagRequest) => ({
					url: `/HoldFlags/Order/${newHoldFlagRequest.orderId}`,
					method: "POST",
					body: newHoldFlagRequest
				}),
				invalidatesTags: [TagTypes.HoldFlag, TagTypes.Order]
			}),
			createACHRejection:builder.mutation<void, NewACHRejectionRequest>({
				query: (newACHRejectionRequest) => ({
					url: `/Payments/ACHRejection`,
					method: "POST",
					body: newACHRejectionRequest
				}),
				invalidatesTags: [TagTypes.Payment, TagTypes.Order]
			}),
			getACHRejectionCodes: builder.query<ACHRejectionCode[], void>({
				query: () => `/Payments/ACHRejectionCodes`
			}),
			getACHPaymentSummaries: builder.query<ACHRejectionCode[], void>({
				query: () => `/Payments/ACHRejectionCodes`
			}),
			getConsumerControlListValues: builder.query<ConsumerControlListValuesVm, number>({
				query: (consumerId) => `/ControlLists/Consumer/${consumerId}/Values`,
				providesTags: [TagTypes.ControlList]
			}),
			getControlListGroups: builder.query<ControlListGroup[], void>({
				query: () => '/ControlLists/Groups'
			}),
			getControlListActions: builder.query<ControlListAction[], void>({
				query: () => '/ControlLists/Actions'
			}),
			getControlListReasons: builder.query<ControlListReason[], void>({
				query: () => '/ControlLists/Reasons'
			}),
			updateControlListValue: builder.mutation<void, ControlListValueSaveRequest>({
				query: (controlListValueUpdateRequest) => ({
					url: `/ControlLists/${controlListValueUpdateRequest.controlListValueId}`,
					method: "PUT",
					body: controlListValueUpdateRequest
				}),
				invalidatesTags: [TagTypes.HoldFlag, TagTypes.Order, TagTypes.ControlList]
			}),
			createControlListValue: builder.mutation<void, ControlListValueSaveRequest>({
				query: (newControlListValueRequest) => ({
					url: `/ControlLists`,
					method: "POST",
					body: newControlListValueRequest
				}),
				invalidatesTags: [TagTypes.HoldFlag, TagTypes.Order, TagTypes.ControlList]
			}),
			deleteControlListValue: builder.mutation<void, number>({
				query: (controlListValueId) => ({
					url: `/ControlLists/${controlListValueId}`,
					method: "DELETE"
				}),
				invalidatesTags: [TagTypes.Consumer, TagTypes.Order, TagTypes.ControlList]
			}),
			getAllConsumers: builder.query<Consumer[], void>({
				query: () => '/Consumers/All'
			}),
			getHomePageBottomContent: builder.query<string, void>({
				query: () => ({
					url: `/SystemParameters/HomePageBottomContent`,
					responseHandler: 'text',
				})
			}),
			getTaxProcessingPreview: builder.query<TaxItem[], string>({
				query: processDate => ({
					url: '/TaxProcessing',
					params: { processDate }, // ISO date, like: 2023-11-08
				}),
				providesTags: [TagTypes.TaxProcessing, TagTypes.Order],
			}),
			getProcessedTaxFilesInRange: builder.query<TaxFile[], InRangeRequestParams>({
				query: ({fromDate, toDate}) => ({
					url: '/TaxProcessing/ProcessedFilesInRange',
					params: { fromDate, toDate },
				}),
				providesTags: [TagTypes.TaxProcessing],
			}),
			getHistoricalExportsInRange: builder.query<TaxItem[], InRangeRequestParams>({
				query: ({fromDate, toDate}) => ({
					url: '/TaxProcessing/HistoricalExportsInRange',
					params: { fromDate, toDate },
				}),
				providesTags: [TagTypes.TaxProcessing],
			}),
			
			// reports:
			getPaymentMethodRollupReport: getReportQuery<PaymentMethodRollup[]>('PaymentMethodRollup'),
			getOrderAchRejectReport: getReportQuery<OrderAchReject[]>('OrderAchReject'),
			getOrderItemDownloadReport: getReportQuery<OrderItemDownload[]>('OrderItemDownload'),
			getOrderStatusReportReport: getReportQuery<OrderStatusReport[]>('OrderStatusReport'),
			getCustomerEmailDownloadReport: getReportQuery<CustomerEmailDownload[]>('CustomerEmailDownload'),
			getOrderDailySalesReport: getReportQuery<OrderDailySales[]>('OrderDailySales'),
			getOrderProductMixReport: getReportQuery<OrderProductMix[]>('OrderProductMix'),
			getOrderMonthEndReport: getReportQuery<OrderMonthEnd[]>('OrderMonthEnd'),
			getLtvReport: getReportQuery<Ltv[]>('Ltv'),
			getOrderRefundReportReport: getReportQuery<OrderRefundReport[]>('OrderRefundReport'),
			getSourceCodeSalesReport: getReportQuery<SourceCodeSales[]>('SourceCodeSales'),
			getTaxReport: getReportQuery<TaxReport[]>('TaxReport'),
			getTransactionReport: getReportQuery<TransactionReport[]>('TransactionReport'),
			getRoyaltiesFeesReport: getReportQuery<RoyaltiesFees[]>('RoyaltiesFees')
		};
	},
});

export const {
	useGetCurrentCartCheckAddressQuery,
	useGetOrderShipmentsByOrderQuery,
	useGetPaymentMethodsQuery,
	useGetPreviousOrderPaymentInformationQuery,
	useGetCartOrderPaymentMethodsQuery,
	useGetSavedPaymentDetailsQuery,
	useGetSavedPaymentDetailsForUserQuery,
	useSavePaymentDetailsMutation,
	useUpdateSavedPaymentDetailsMutation,
	useDeleteSavedPaymentDetailsMutation,
	useDeleteSavedPaymentDetailsForUserMutation,
	useGetSiteCardsQuery,
	useGetSiteCardItemsQuery,
	useGetBannersByTypeQuery,
	useGetCurrentUsersOrdersQuery,
	useGetBulletPointsByIdQuery,
	useGetOrderOverviewQuery,
	useGetOrderStatusesQuery,
	useGetAvailableOrderStatusesQuery,
	useAuthenticateMutation,
	useGetSitesQuery,
	useGetConsumerDetailsByIdQuery,
	useGetCustomersQuery,
	useUpdateConsumerMutation,
	useGetStatesQuery,
	useGetImagesQuery,
	useGetAspectRatiosQuery,
	useGetImageHostQuery,
	useGetCustomLogoQuery,
	useUpdateCustomLogoMutation,
	useGetCustomOverlayQuery,
	useUploadCustomOverlayMutation,
	useGetAddressesByConsumerIdQuery,
	useGetConsumerAddressByAddressIdQuery,
	useGetBillingAddressByOrderIdQuery,
	useSaveAddressMutation,
	useUpdateAddressMutation,
	useDeleteAddressMutation,
	useGetSiteProductsQuery,
	useGetProductQuery,
	useGetSiteProductByUrlNameQuery,
	useGetSiteProductVariantsBySiteProductIdQuery,
	useGetSiteProductVariantByIdQuery,
	useGetSiteProductVariantQuantityOptionsQuery,
	useGetSiteProductVariantQuantityOptionQuery,
	useGetSiteProductVariantWithConfigurationsPricingQuery,
	useGetComponentsForSiteProductQuery,
	useGetConfigurationsForSiteProductQuery,
	useGetComponentsForVariantQuery,
	useGetAllCategoriesQuery,
	useGetSiteCategoryByUrlNameOrIdQuery,
	useGetParentSiteCategoriesQuery,
	useGetChildSiteCategoriesQuery,
	useSubscribeMutation,
	useSubscribeCurrentUserMutation,
	useIsUserSubscribedQuery,
	useGetCategoryProductsQuery,
	useGetCartExistsQuery,
	useSignInCartCheckMutation,
	useGetCartProductsQuery,
	useGetCartProductInputValuesQuery,
	useGetOrderProductInputValuesQuery,
	useGetCartProductsPricingQuery,
	useAddProductToCartMutation,
	useRemoveProductFromCartMutation,
	useRemoveAllProductsFromCartMutation,
	useCreateCartCheckoutEventMutation,
	useContactMutation,
	useValidateBankingUserInputMutation,
	useValidateUserInputMutation,
	useGetAdminsQuery,
	useGetRolesQuery,
	useGetClaimsQuery,
	useSaveAdminMutation,
	useSendOrderConfirmationEmailMutation,
	useSendOrderTrackingEmailMutation,
	useGetCurrentConsumerDetailsQuery,
	useGetConsumersOrdersQuery,
	useGetUserHistoryQuery,
	useCreateOrderMutation,
	useUpdateOrderByCartIdMutation,
	useFetchShippingOptionsQuery,
	useSignUpMutation,
	useSendForgotPasswordEmailMutation,
	useSetPasswordMutation,
	useRequestNewResetTokenLinkMutation,
	useSendPasswordSetupEmailMutation,
	useGetIsResetPasswordTokenValidQuery,
	useGetPrintingSoftwareQuery,
	useGetSiteProductForAccessoryQuery,
	useGetFinancialInstitutionsQuery,
	useGetFinancialInstitutionByIdQuery,
	useGetFinancialInstitutionByAbaQuery,
	useGetMicrFormatsQuery,
	useGetMicrFormatByIdQuery,
	useGetBankInformationByOrderProoductIdQuery,
	useSaveMicrFormatMutation,
	usePreviewMicrFormatQuery,
	useSaveFinancialInstitutionMutation,
	useGetMicrCheckProductTypesQuery,
	useGetFinancialInstitutionHistoryQuery,
	useGetMicrFormatHistoryQuery,
	useGetMicrFormatIdsByOrderIdQuery,
	useGetOrderProductAccountNumberLengthByIdQuery,
	useGetFinancialInstitutionIdByOrderIdQuery,
	useCheckOrderCreationValidationQuery,
	useGetVendorOrderProductStatusQuery,
	useGetOrderProductsQuery,
	useGetOrderPricingQuery,
	useGetOrderProductPricingQuery,
	useGetOrderShippingInformationQuery,
	useGetOrderPaymentHistoryQuery,
	useGetFailedPaymentsHistoryQuery,
	useGetOrderPaymentsQuery,
	useGetOrderProductPaymentsQuery,
	useHasLastOrderPaymentFailedQuery,
	useGetPaymentTransactionStatusQuery,
	useGetOrderTrackingInformationQuery,
	useGetOrderByCartIdQuery,
	useGetAdminOrderTrackingInformationQuery,
	useGetConsumerOrderTrackingInformationQuery,
	useValidateTrackingInformationMutation,
	useUpdateOrderMutation,
	useMigrateOrderProductsToCartMutation,
	useCreateOrderEditCartMutation,
	useReprintOrderProductMutation,
	useCancelOrderProductReprintMutation,
	useGetRecentOrderProductsQuery,
	useUpdateOrderProductMutation,
	useUpdateUserProfileDetailsMutation,
	useGetOrderStatusReasonsQuery,
	useGetProductPreviewQuery,
	useGetOrderRefundsQuery,
	useGetRefundTypesQuery,
	useGetRefundCategoriesQuery,
	useGetRefundReasonsQuery,
	useGetOrderProductPaymentRefundsByOrderIdQuery,
	useSubmitRefundRequestMutation,
	useApproveDenyRefundRequestMutation,
	useGetOrderHistoryQuery,
	useGetRelatedOrdersQuery,
	useSaveDiscountMutation,
	useDeleteDiscountMutation,
	useGetDiscountTypesQuery,
	useGetOrderNoteCategoriesQuery,
	useGetOrderNotesQuery,
	useAddOrderNotesMutation,
	useGetHoldFlagReasonsQuery,
	useGetHoldFlagStatusesQuery,
	useUpdateHoldFlagMutation,
	useCreateHoldFlagMutation,
	useCreateACHRejectionMutation,
	useGetACHRejectionCodesQuery,
	useGetControlListGroupsQuery,
	useGetControlListActionsQuery,
	useGetControlListReasonsQuery,
	useUpdateControlListValueMutation,
	useCreateControlListValueMutation,
	useDeleteControlListValueMutation,
	useGetConsumerControlListValuesQuery,
	useGetAllConsumersQuery,
	useGetHomePageBottomContentQuery,
	useGetTaxProcessingPreviewQuery,
	useGetProcessedTaxFilesInRangeQuery,
	useGetHistoricalExportsInRangeQuery,
	useGetPaymentMethodRollupReportQuery,
	useGetOrderAchRejectReportQuery,
	useGetOrderItemDownloadReportQuery,
	useGetOrderStatusReportReportQuery,
	useGetCustomerEmailDownloadReportQuery,
	useGetOrderDailySalesReportQuery,
	useGetOrderProductMixReportQuery,
	useGetOrderMonthEndReportQuery,
	useGetLtvReportQuery,
	useGetOrderRefundReportReportQuery,
	useGetSourceCodeSalesReportQuery,
	useGetTaxReportQuery,
	useGetTransactionReportQuery,
	useGetRoyaltiesFeesReportQuery,
} = apiSlice;
