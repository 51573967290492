import { Address, DefaultAddressType } from "./Address";
import { isUsingAdminSite, useGetAddressesByConsumerIdQuery } from "../../../app/apiSlice";
import React from "react";
import { ValidatedAddressInput } from "../validatedAddress/ValidatedAddressInput";
import { SavedAddressSelect } from "./SavedAddressSelect";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentConsumerUser } from "../../user/login/AuthenticationSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import {AddressInputVm} from "./Address";

interface ConsumerAddressInputProps {
  address?: AddressInputVm
  onAddressChanged: (address?: Address) => void
  consumerId?: number // uses signed in consumer by default if not provided
  defaultAddress?: DefaultAddressType
  locked?: boolean
  isReadOnly?: boolean
  isShippingAddress?: boolean
  suppressError?: boolean
}
export const ConsumerAddressInput = (
  { address, onAddressChanged, consumerId, defaultAddress,
    locked = false,
    isReadOnly, isShippingAddress, suppressError
  }: ConsumerAddressInputProps
) => {
  const isAdminSite = isUsingAdminSite();
  const signedInConsumerId = useAppSelector(selectCurrentConsumerUser)?.id;
  // don't use signedInConsumer if on admin site
  const { data: savedAddresses } = useGetAddressesByConsumerIdQuery(isAdminSite && !consumerId ? skipToken : consumerId ?? signedInConsumerId ?? skipToken);
  const usingConsumerSavedAddress = () =>
      !!savedAddresses?.find(a => a.id === address?.id);
  return (<>
    { !isReadOnly && <SavedAddressSelect
        onAddressSelected={onAddressChanged}
        consumerId={consumerId}
        address={address}
        defaultAddress={defaultAddress}
        selectedAddressId={address?.id}
        shouldUseDefault={true}/> }
    {
      (!usingConsumerSavedAddress() || isReadOnly) &&
        <ValidatedAddressInput
            value={address ?? {}}
            onChange={onAddressChanged}
            required
            suppressError={suppressError}
            disableBrowserAutoComplete={isAdminSite}
            isShippingAddress={isShippingAddress}
            disabled={locked || isReadOnly}/>
    }
  </>)
}