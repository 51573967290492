import {Brand, selectedBrand} from "../i18n";
import {isUsingAdminSite} from "../app/apiSlice";
import {bingAnalytics, googleAnalytics} from "../analyticsConfig";
import {CartPricing, SiteProductVariantPricing} from "../components/cart/CartPricing";
import {CartProductVM} from "../components/cart/CartProduct";
import {Refund} from "../components/admin/order/tabs/refund/Refund";

// TODO: Remove this and use env info when it is set up properly in pipeline builds.
function isSendAnalytics() {
	const host = window.location.hostname.toLowerCase();
	const isProduction = host.indexOf("checkworks.com") > -1 || host.indexOf("checksforfree.com") > -1;
	return (!isUsingAdminSite() && isProduction);
}

// Interfaces.

interface GoogleAnalytics {
	AnalyticsSendTo: string,
	ConversionSendTo: string,
	ScriptSrc: string,
	IFrameScriptSrc: string,
	ScriptContent: string[]
}

interface BingAnalytics {
	IsSupported: boolean,
	ScriptContent: string[]
}

// Retrieve configurations.

export function getGoogleAnalyticsConfig(brand: Brand): GoogleAnalytics {
	if (isSendAnalytics()) {
		switch (brand) {
			case Brand.ChecksForFree:
				return googleAnalytics.ChecksForFree;
			case Brand.CheckWorks:
				return googleAnalytics.CheckWorks;
		}
	}
	return {
		AnalyticsSendTo: "",
		ConversionSendTo: "",
		ScriptSrc: "",
		IFrameScriptSrc: "",
		ScriptContent: []
	};
}

export function getBingAnalyticsConfig(brand: Brand): BingAnalytics {
	if (isSendAnalytics()) {
		switch (brand) {
			case Brand.ChecksForFree:
				return bingAnalytics.ChecksForFree;
			case Brand.CheckWorks:
				return bingAnalytics.CheckWorks;
		}
	}
	return {
		IsSupported: false,
		ScriptContent: []
	};
}

// Event capture class.

export class AnalyticsTools {
	private static gaConfig = getGoogleAnalyticsConfig(selectedBrand);
	private static baConfig = getBingAnalyticsConfig(selectedBrand);
	
	// Public event capture functions.
	
	public static recordOrderEvent = (orderId: number, cartPricing: CartPricing, cartProducts: CartProductVM[]) => {
		// Google.
		const sendTo = this.gaConfig.AnalyticsSendTo;
		if (!sendTo) return;

		const itemList = cartProducts?.map(cp => {
			return {
				item_id: cp.sku,
				item_name: cp.siteProductName,
				price: `${cartPricing.siteProductVariantTotals?.find(spvt => spvt.cartProductId === cp.id)?.total ?? 0}`,
				quantity: cp.quantityOption.quantity
			}
		});

		// @ts-ignore
		window.gtag("event", "purchase", {
			transaction_id: `${orderId}`,
			tax: cartPricing.taxAmount ?? 0,
			shipping: cartPricing.shippingTotal ?? 0,
			value: cartPricing.total,
			currency: "USD",
			items: itemList,
			'send_to': sendTo
		});

		// Bing.
		if (this.baConfig.IsSupported) {
			cartPricing.siteProductVariantTotals?.forEach((spvt) => {
				this.buildBingPurchaseEvent(spvt.siteProductVariantId, spvt.total);
			});
		}
	};

	public static recordAddToCartEvent = (cartProduct: CartProductVM) => {
		const sendTo = this.gaConfig.AnalyticsSendTo;
		if (!sendTo) return;

		const itemList = [{
			item_id: cartProduct.sku,
			item_name: cartProduct.siteProductName,
			price: `${cartProduct.price}`,
			quantity: cartProduct.quantityOption.quantity
		}];

		// @ts-ignore
		window.gtag("event", "add_to_cart", {
			value: cartProduct.price,
			currency: "USD",
			items: itemList,
			'send_to': sendTo
		});
	};

	public static recordRemoveToCartEvent = (cartProduct: CartProductVM) => {
		const sendTo = this.gaConfig.AnalyticsSendTo;
		if (!sendTo) return;

		const itemList = [{
			item_id: cartProduct.sku,
			item_name: cartProduct.siteProductName,
			price: `${cartProduct.price}`,
			quantity: cartProduct.quantityOption.quantity
		}];

		// @ts-ignore
		window.gtag("event", "remove_from_cart", {
			value: cartProduct.price,
			currency: "USD",
			items: itemList,
			'send_to': sendTo
		});
	};

	public static recordRefundEvent = (orderId: number, orderProductPricing: SiteProductVariantPricing, refund: Refund) => {
		const sendTo = this.gaConfig.AnalyticsSendTo;
		if (!sendTo) return;

		const itemList = [{
			item_id: orderProductPricing.sku,
			item_name: orderProductPricing.name,
			discount: `${refund.amount}`,
			price: `${orderProductPricing.total - refund.amount}`,
			'send_to': sendTo
		}];

		// @ts-ignore
		window.gtag("event", "refund", {
			transaction_id: `${orderId}`,
			value: orderProductPricing.total,
			currency: "USD",
			items: itemList
		});
	};

	public static recordConversionEvent = (orderId: number, cartPricing: CartPricing) => {
		const sendTo = this.gaConfig.ConversionSendTo;
		if (!sendTo) return;

		// @ts-ignore
		window.gtag("event", "conversion", {
			transaction_id: `${orderId}`,
			value: cartPricing.total,
			currency: "USD",
			'send_to': sendTo
		})
	};

	public static recordProductConfigPageLoadEvent = (configPageName: string | undefined, configPageIndex: number) => {
		const sendTo = this.gaConfig.AnalyticsSendTo;
		if (!sendTo) return;

		// Set event name based on config page.
		let pageName = "name_undefined";
		if (configPageName !== undefined) {
			pageName = configPageName.toLowerCase().replaceAll(" ", "_");
		}
		const eventName = "product_config_" + pageName;

		// @ts-ignore
		window.gtag("event", eventName, {
			configPageIndex,
			'send_to': sendTo
		});
	};
	
	// Private methods.
	
	private static buildBingPurchaseEvent(productId: number, total: number) {
		// @ts-ignore
		window.uetq = window.uetq || [];
		// @ts-ignore
		window.uetq.push('event', 'PRODUCT_PURCHASE', {
			"ecomm_prodid": productId,
			"ecomm_pagetype": "PURCHASE",
			"revenue_value": total,
			"currency": "USD"
		});
		// @ts-ignore
		window.uetq.push('event', '', {
			"revenue_value": total,
			"currency": "USD"
		});
	};
}
