import {PaymentDetails} from "../PaymentDetails";
import {Button, FormFeedback, Input, Label, Spinner} from "reactstrap";
import {SavedPaymentDetails} from "../SavedPaymentDetails";
import './SavedPaymentSelection.css';
import React, {useEffect, useState} from "react";
import {getPrettyAddress} from "../../../utils/Utils";
import {useAppDispatch, ValidationHook} from "../../../app/hooks";
import {showConfirmationModal} from "../../modal/ModalSlice";

interface SavedPaymentSelectionProps {
    selectedPaymentProfileId?: string,
    savedPaymentDetails?: SavedPaymentDetails[],
    onPaymentDetailsChanged: (paymentDetails?: PaymentDetails) => void,
    onRemoveSavedPaymentMethodClicked: (paymentProfileId: string) => void,
    validation?: ValidationHook,
    isDeletingSavedPayment: boolean
}

export const SavedPaymentSelection = ({selectedPaymentProfileId, savedPaymentDetails, onPaymentDetailsChanged,
                                          onRemoveSavedPaymentMethodClicked, validation, isDeletingSavedPayment}: SavedPaymentSelectionProps) => {

    const hasSavedPaymentMethods = savedPaymentDetails && savedPaymentDetails.length > 0;
    const [ currentSelectedPaymentProfileId, setCurrentSelectedPaymentProfileId ] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        setCurrentSelectedPaymentProfileId(selectedPaymentProfileId);
        validation?.setValue('savedMethod', selectedPaymentProfileId);
    }, [selectedPaymentProfileId]);

    const onRadioChange = (savedPaymentDetails: SavedPaymentDetails) => {
        setCurrentSelectedPaymentProfileId(savedPaymentDetails.paymentProfileId);
        paymentDetailsChanged(savedPaymentDetails);
    }

    const isOptionSelected = (paymentProfileId: string) => {
        return (currentSelectedPaymentProfileId === paymentProfileId);
    }

    const onSavedPaymentDetailsClicked = (savedPaymentDetails: SavedPaymentDetails) => {
        setCurrentSelectedPaymentProfileId(savedPaymentDetails.paymentProfileId);
        paymentDetailsChanged(savedPaymentDetails);
    }
    
    const paymentDetailsChanged = (savedPaymentDetails: SavedPaymentDetails) => {
        validation?.setValue('savedMethod', savedPaymentDetails.paymentProfileId);
        onPaymentDetailsChanged(savedPaymentDetails);
    }

    const removeSavePaymentClicked = (paymentProfileId: string) => {
        const savedPaymentMethod = savedPaymentDetails?.find(spd => spd.paymentProfileId === paymentProfileId) as SavedPaymentDetails
        dispatch(showConfirmationModal({
            title: `Delete Saved Payment Method`,
            content: `
            Are you sure you want to delete this Saved Payment Method?
            ${savedPaymentMethod.cardNumber} - ${savedPaymentMethod.expirationDateString}
            `,
            affirmText: 'Delete',
            onConfirm: () => onRemoveSavedPaymentMethodClicked(paymentProfileId)
        }));
    }
    
    const SavedPaymentMethodList = () => (
        <span>
            Select a saved payment:

            <span className="saved-payment-selection-option-container">
                {savedPaymentDetails?.map((spd) => {
                    return (
                        <span className="saved-payment-selection-option-input-container" key={spd.paymentProfileId}>
                            <span className="saved-payment-selection-option" onClick={() => onSavedPaymentDetailsClicked(spd)}>
                                <Input
                                    name="saved-payment-selection-radio"
                                    type="radio"
                                    value={spd.paymentProfileId}
                                    checked={isOptionSelected(spd.paymentProfileId) ?? false}
                                    onChange={() => onRadioChange(spd)}
                                ></Input>
                                <Label className="saved-payment-selection-option-label">
                                    <div className="saved-payment-details-text-container">
                                        <div className="saved-payment-details-card-number-preview">{spd.cardNumber + ' - ' + spd.expirationDateString}</div>
                                        <div className="saved-payment-details-address-value">{getPrettyAddress(spd.billingAddress)}</div>
                                    </div>
                                </Label>
                            </span>
                            <Button color="danger"
                                    onClick={() => removeSavePaymentClicked(spd.paymentProfileId)}
                                    disabled={isDeletingSavedPayment}>
                                {isDeletingSavedPayment && <Spinner/>}
                                Remove
                            </Button>
                        </span>
                    );
                })}
            </span>
            <FormFeedback>{validation?.getError('savedMethod')}</FormFeedback>
        </span>
    );
    
    const NoSavedPaymentsWarning = () => (
        <div className="alert alert-warning">
            There is no Saved Payment Method on file. To continue, select <strong>New Payment Method</strong>.
        </div>
    )
    return hasSavedPaymentMethods
        ? <SavedPaymentMethodList/>
        : <NoSavedPaymentsWarning />;
}