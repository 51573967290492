import {PaymentMethod} from "./PaymentMethod";
import {Address} from "../input/address/Address";

export interface PaymentDetails {
    paymentMethodId: number,
    paymentDetailsType: string
}

export interface PaymentInformation {
    paymentMethod: PaymentMethod;
    billingAddress?: Address;
    vendorPaymentProfileId?: string;
}

export interface SavePaymentDetailsRequest {
    customerPaymentProfileId?: string,
    paymentDetails: PaymentDetails,
    userId: number
}

export interface DeletePaymentDetailsRequest {
    customerPaymentProfileId: string,
    userId: number
}

export enum PaymentDetailsType {
    ACHPaymentDetails = "ACHPaymentDetails",
    CreditCardPaymentDetails = "CreditCardPaymentDetails",
    SavedPaymentDetails = "SavedPaymentDetails"
}