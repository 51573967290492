import './CartCheckout.css';
import {useTranslation} from "react-i18next";
import {Button, Container, Spinner} from "reactstrap";
import {
	useCreateOrderMutation,
	useDeleteSavedPaymentDetailsMutation,
	useGetCartProductsPricingQuery,
	useGetCartProductsQuery,
	useGetCurrentCartCheckAddressQuery,
	useGetSavedPaymentDetailsQuery,
	useSavePaymentDetailsMutation,
} from "../../../app/apiSlice";
import {
	useAppDispatch,
	useAppSelector,
	useDebounce,
	useShippingAddressValidation,
	validationRequired,
} from "../../../app/hooks";
import {selectCartId} from "../CartSlice";
import {CartOverview} from "../overview/CartOverview";
import ScrollToTop from "../../helpers/ScrollToTop";
import {useCallback, useEffect, useMemo, useState} from "react";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {ShippingInformation, ShippingInformationCard} from "../../admin/order/ShippingInformation";
import {Consumer} from "../../admin/customer/Consumer";
import {Address} from "../../input/address/Address";
import {ShippingOption} from "../../shipping-options/ShippingOption";
import {PaymentDetails, PaymentDetailsType} from "../../payment/PaymentDetails";
import {
	getErrorMessage,
	Regexes,
	validateCCFullNameString,
	validateCVCString,
	validateExpirationDateString,
} from "../../../utils/Utils";
import {selectCurrentConsumerUser} from "../../user/login/AuthenticationSlice";
import {ShippingAndHandlingSelector} from "../../shipping-options/ShippingAndHandlingSelector";
import {BillingAddress} from "../../admin/order/BillingAddress";
import {PaymentSelection} from "../../payment/selection/PaymentSelection";
import {SaveOrderVm} from "../../admin/order/SaveOrderVm";
import {useNavigate} from "react-router-dom";
import {CartPricingRequest} from "../CartPricing";
import {skipToken} from "@reduxjs/toolkit/query";
import {PaymentMethodOption} from "../../payment/PaymentMethod";
import {PaymentUtils} from "../../payment/utils/PaymentUtils";
import {OrderCreationResponse} from "../../order/Order";
import {showConfirmationModal, showInformationModal} from "../../modal/ModalSlice";
import {StickySidebar} from '../StickySidebar';
import {setLatestOrderCreationResponse} from "../../order/OrderSlice";
import {AnalyticsTools} from "../../../utils/AnalyticsHelper";

interface PlaceOrderButtonProps {
	submitOrder: () => void,
	getPlaceOrderButtonText: () => string | JSX.Element,
	disabled: boolean
}

const PlaceOrderButton = ({submitOrder, getPlaceOrderButtonText, disabled}: PlaceOrderButtonProps) =>
	<Button color="primary"
	        onClick={submitOrder}
	        disabled={disabled}
	        className="cart-checkout-proceed-button">
		{getPlaceOrderButtonText()}
	</Button>

const ValidationMessage = ({isInvalid}: { isInvalid: boolean }) =>
	isInvalid
		? <div className="cart-checkout-invalid-input">
			<p>There is some missing or incorrect information.</p>
			<p>Please make sure to enter everything that is highlighted.</p>
		</div>
		: <></>;

interface CartCheckoutState {
	consumer?: Consumer
	shipping?: ShippingInformation
	billingAddress?: Address,
	cartId?: number,
	shippingOption?: ShippingOption,
	paymentDetails?: PaymentDetails,
	selectedPaymentProfileId?: string
}

export const CartCheckout = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const cartId = useAppSelector(selectCartId);
	const {data: checkAddress} = useGetCurrentCartCheckAddressQuery();
	const consumer = useAppSelector(selectCurrentConsumerUser);
	const [state, setState] = useState<CartCheckoutState>({});
	const {data: cartProducts} = useGetCartProductsQuery(cartId);
	const [cartPricingRequest, setCartPricingRequest] = useState<CartPricingRequest | undefined>();
	const {
		data: cartPricing,
		isFetching: isFetchingCartPricing
	} = useGetCartProductsPricingQuery(cartPricingRequest ?? skipToken);
	const [paymentMethodOption, setPaymentMethodOption] = useState<PaymentMethodOption>();
	const {data: savedPaymentDetails} = useGetSavedPaymentDetailsQuery(consumer ? undefined : skipToken);
	const [savePaymentDetails, {isLoading: isSavingPaymentDetails}] = useSavePaymentDetailsMutation();
	const [deleteSavedPaymentDetails, {isLoading: isDeletingSavedPayment}] = useDeleteSavedPaymentDetailsMutation();
	const [createOrder, {isLoading: isPlacingOrder}] = useCreateOrderMutation();
	const showFillFromCheck = checkAddress && Object.values(checkAddress).some(v => v != null);
	const validation = useShippingAddressValidation({
		payment_method: validationRequired('Payment type'),
		...PaymentUtils.wrapPaymentMethodOptionValidationMap(paymentMethodOption, PaymentMethodOption.NewPaymentMethod,
			{
				payment_firstName: validationRequired('First Name'),
				payment_lastName: validationRequired('Last Name'),
				payment_fullName: val => validateCCFullNameString(val),
				payment_cardNumber: val => (!val || !Regexes.ccWithFormatting.test(val)) ? 'Please enter a valid card number' : undefined,
				payment_cardExpiration: val => validateExpirationDateString(val),
				payment_cvc: val => validateCVCString(val),
			}
		),
		...PaymentUtils.wrapPaymentMethodOptionValidationMap(paymentMethodOption, PaymentMethodOption.SavedPaymentMethod,
			{
				payment_savedMethod: validationRequired('Payment Method')
			}
		)
	});

	const hasShippingOptionSelected = () => ((state.shippingOption?.items?.length ?? 0) >= 1);

	useEffect(() => {
		if (cartId) {
			const productShippingServiceSelections = state.shippingOption?.items.map((i) => {
				return {
					cartProductId: i.cartProductId,
					cost: i.price ?? 0,
					retailCost: i.retailPrice ?? 0
				}
			}) ?? [];
			setCartPricingRequest({cartId, toAddress: state.shipping?.address, productShippingServiceSelections});
		} else {
			setCartPricingRequest(undefined);
		}
	}, [cartId, state.shipping?.address, state.shippingOption]);

	const hasAllOrderRequiredInfo = useMemo(() => {
		const hasShippingOption = ((state.shippingOption?.items?.length ?? 0) >= 1);
		const hasCartProducts = cartProducts && cartProducts.length > 0;
		const hasShippingAddress = !!(state.shipping?.address?.street && state.shipping.address.city && state.shipping.address.stateCode && state.shipping.address.zip);
		const hasPaymentDetails = !!state.paymentDetails;
		const hasBillingAddressOrIsSavedPayment = !!((state.billingAddress?.street && state.billingAddress?.city && state.billingAddress.stateCode && state.billingAddress.zip)
			|| state.paymentDetails?.paymentDetailsType === PaymentDetailsType.SavedPaymentDetails);

		return hasShippingOption && hasCartProducts && hasShippingAddress && hasPaymentDetails && hasBillingAddressOrIsSavedPayment;
	}, [cartProducts, state.shippingOption, state.shipping, state.paymentDetails, state.billingAddress]);

	const onShippingChanged = useCallback((shipping: ShippingInformation, hasAddress: boolean = true) => {
		const setValue = (name: string, val: string) => validation.setValue(`shipping_${name}`, val);
		setState(old => ({
			...old,
			shipping: {
				...old.shipping,
				...shipping,
				address: hasAddress ? shipping.address : old.shipping?.address,
			}
		}));

		// set these values for shipping
		setValue('firstName', shipping.firstName ?? "");
		setValue('lastName', shipping.lastName ?? "");
		setValue('email', shipping.email ?? "");
		setValue('repeatEmail', shipping.repeatEmail ?? "");
		setValue('phoneNumber', shipping.phoneNumber ?? "");
	}, [validation]);

	const isPlaceOrderButtonDisabled = useMemo(() => !hasAllOrderRequiredInfo || isPlacingOrder || isFetchingCartPricing,
		[hasAllOrderRequiredInfo, isPlacingOrder, isFetchingCartPricing]);
	// Debouncing disabled property because shipping option request can initiate a subsequent pricing request
	// Bypass the debounce if the value becomes true, we only want to debounce making the button enabled
	const debouncedIsPlaceOrderButtonDisabled = useDebounce(isPlaceOrderButtonDisabled, 250, isPlaceOrderButtonDisabled);

	const onShippingOptionChanged = (shippingOption?: ShippingOption) => {
		setState(old => ({
			...old,
			shippingOption,
		}));
	}

	const onPaymentDetailsChanged = (paymentDetails?: PaymentDetails) => {
		setState(old => ({
			...old,
			paymentDetails,
		}));
	}

	const onBillingChanged = (billingAddress?: Address) => {
		setState(old => ({
			...old,
			billingAddress: {
				...billingAddress,
				id: billingAddress?.id ?? -1
			}
		}));
	}

	const onSavePaymentDetailsClicked = async () => {
		if (!state.paymentDetails || !consumer?.id) {
			dispatch(showInformationModal({
				title: 'Missing Information',
				text: 'Please enter all of the payment fields and enter a billing address.'
			}));
			return;
		}

		try {
			const newPaymentProfileId = await savePaymentDetails({
				paymentDetails: state.paymentDetails,
				userId: consumer.userId
			}).unwrap();

			if (newPaymentProfileId) {
				dispatch(showInformationModal({
					title: 'Saved!',
					text: 'Payment method saved.',
				}));
			} else {
				dispatch(showInformationModal({
					title: 'Failed to Save!',
					text: "Failed to save payment method. It's possible this payment method may already be saved."
				}));
			}
		} catch (e) {
			dispatch(showInformationModal({
				title: 'Failed to Save!',
				text: 'Failed to save payment method.'
			}));
		}

	}

	const onRemoveSavedPaymentMethodClicked = async (customerPaymentProfileId: string) => {
		if (!consumer?.id) {
			return;
		}

		try {
			await deleteSavedPaymentDetails(customerPaymentProfileId).unwrap();
		} catch (e) {
			alert('Error deleting payment details: ' + JSON.stringify(e));
		}
	}

	const fillAddress = (address: 'shipping' | 'billingAddress') => {
		const setValidationVal = (name: string, val: string) => validation.setValue(`${address === 'shipping' ? 'shipping' : 'billing'}_${name}`, val);
		if (address === 'shipping') {
			const parts = checkAddress?.name?.split(' ') ?? ['', ''];
			const firstName = parts[0];
			const lastName = getLastNameFromParts(parts);

			setState(old => ({
				...old,
				shipping: {
					...old.shipping,
					firstName: firstName,
					lastName: lastName,
					phoneNumber: checkAddress?.phoneNumber ?? '',
					address: {...checkAddress, name: checkAddress?.name, id: undefined, isVerified: false},
				}
			}));
			// shipping specific validation fields
			setValidationVal('firstName', firstName);
			setValidationVal('lastName', lastName);
			setValidationVal('phoneNumber', checkAddress?.phoneNumber ?? '');
		} else {
			setState(old => ({
				...old,
				billingAddress: {...checkAddress, id: undefined, isVerified: false},
			}));
		}
	}

	const getLastNameFromParts = (parts: string[]) => {
		if (parts.length <= 1) return '';
		const suffix = parts.find(part => Regexes.suffix.test(part.toLowerCase()));

		if (suffix) {
			const suffixIndex = parts.indexOf(suffix);
			return `${parts[suffixIndex - 1]} ${parts[suffixIndex]}`
		} else {
			return parts[parts.length - 1];
		}
	}

	const getPlaceOrderButtonText = () => {
		if (hasAllOrderRequiredInfo && cartPricing?.total && !isFetchingCartPricing) {
			return !isPlacingOrder ? `Place order for ${CurrencyFormatter.format(cartPricing.total)}` : (
				<>
					<Spinner></Spinner>
					{`Placing order for ${CurrencyFormatter.format(cartPricing.total)}`}
				</>
			);
		} else {
			return 'Place order';
		}
	}

	const validate = () => {
		let allGood = true;
		let hints = '';

		if (((state.shippingOption?.items?.length ?? 0) < 1)) {
			allGood = false;
			hints += ' Please select products and a shipping option for the order. ';
		}

		if (!allGood) {
			dispatch(showInformationModal({
				title: 'Order Validation',
				text: hints,
			}))
		}
		return allGood;
	};

	const submitOrder = async () => {
		if (
			isPlacingOrder
			|| !validation.allValid()
			|| !validate()
			|| !hasAllOrderRequiredInfo
		) return;

		const orderConsumer = consumer ?? {
			id: -1,
			userId: -1,
			siteId: -1,
			firstName: state.shipping!.firstName,
			lastName: state.shipping!.lastName,
			email: state.shipping!.email!,
			phoneNumber: state.shipping!.phoneNumber,
		} as Consumer;
		const order: SaveOrderVm = {
			orderId: -1,
			cartId,
			email: state.shipping?.email ?? "",
			phoneNumber: state.shipping?.phoneNumber ?? "",
			orderOrigin: 'Customer Site',
			consumer: orderConsumer,
			shippingAddress: {
				...state.shipping?.address,
				id: state.shipping?.address?.id ?? -1,
				name: state.shipping?.firstName,
				nameTwo: state.shipping?.lastName
			},
			changeType: 'New Order',
			paymentDetails: state.paymentDetails,
			cartProductShippingEstimateIds: state.shippingOption?.items?.map(i => i.cartProductShippingEstimateId) ?? []
		};

		let orderCreationResponse: OrderCreationResponse | undefined = undefined;
		try {
			orderCreationResponse = await createOrder(order).unwrap();
		} catch (e: any) {
			if (e && e.status === 409) {
				dispatch(showConfirmationModal({
					title: 'This email address is associated with an account!',
					showTextContent: false,
					affirmText: 'Sign in to use this email address',
					onConfirm: onSignInAndContinueConfirm
				}));
			} else if (e && e.status === 403) {
				dispatch(showInformationModal({
					title: 'Failed to Create Order!',
					text: 'Please contact customer support for assistance at ' + t('phoneNumber.number') + ', ' + t('phoneNumber.hours'),
				}));
			} else {
				dispatch(showInformationModal({
					title: 'Failed to Create Order!',
					text: getErrorMessage(e) ?? 'Failed to create order.'
				}));
			}
		}
		if (orderCreationResponse && cartPricing) {
			AnalyticsTools.recordOrderEvent(orderCreationResponse.orderId, cartPricing, cartProducts ?? []);
			AnalyticsTools.recordConversionEvent(orderCreationResponse.orderId, cartPricing);
			dispatch(setLatestOrderCreationResponse(orderCreationResponse))
			navigate(`/orderConfirmation`);
		}
	};

	const onSignInAndContinueConfirm = () => {
		navigate('/login?redirectTo=/checkout');
	}

	const shouldHideBillingAddress = () => {
		return state.paymentDetails?.paymentDetailsType === PaymentDetailsType.SavedPaymentDetails;
	}

	// if we have a consumer, go ahead and prefill some of the shipping information
	useEffect(() => {
		if (consumer) {
			onShippingChanged({
				firstName: consumer.firstName,
				lastName: consumer.lastName,
				email: consumer.email,
				repeatEmail: consumer.email,
				phoneNumber: consumer.phoneNumber,
			}, false);
		}
	}, [consumer])

	return (
		<span className="cart-checkout-container">
			<ScrollToTop></ScrollToTop>
			<Container>

				<span className="cart-checkout-header-container">
					<span>
						<h1>{t(`cart.checkout.title`)}</h1>
					</span>
				</span>

				<span className="cart-checkout-content-container">
					<span className="cart-checkout-lane-one">
						<form id="shipping-info-form">
							<ShippingInformationCard
								consumerId={consumer?.id}
								shipping={state.shipping}
								validation={validation.createProxy('shipping')}
								suppressError={!validation.shouldShowErrors}
								onFillFromCheck={showFillFromCheck ? () => fillAddress('shipping') : undefined}
								onShippingChanged={onShippingChanged}
							/>
						</form>

						<br/>

						<ShippingAndHandlingSelector
							consumerId={consumer?.id}
							cartId={cartId}
							onChange={onShippingOptionChanged}
							value={state.shippingOption?.id}
							toAddress={state.shipping?.address}/>

						<br/>

						{!shouldHideBillingAddress() && <form id="billing-info-form">
                            <BillingAddress
                                consumerId={consumer?.id}
                                address={state.billingAddress}
                                onFillFromCheck={showFillFromCheck ? () => fillAddress('billingAddress') : undefined}
                                onAddressChanged={onBillingChanged}
                                suppressError={!validation.shouldShowErrors}
                                useCardContainer={true}/>
                            <br/>
                        </form>}

						<PaymentSelection
							selectedPaymentProfileId={state.selectedPaymentProfileId}
							enabledSavedPaymentMethods={!!consumer}
							savedPaymentDetails={savedPaymentDetails}
							billingAddress={state.billingAddress}
							onPaymentDetailsChanged={onPaymentDetailsChanged}
							onSavePaymentDetailsClicked={onSavePaymentDetailsClicked}
							onRemoveSavedPaymentMethodClicked={onRemoveSavedPaymentMethodClicked}
							onPaymentOptionMethodChange={setPaymentMethodOption}
							validation={validation.createProxy('payment')}
							isSavingPaymentDetails={isSavingPaymentDetails}
							isDeletingSavedPayment={isDeletingSavedPayment}
							useCardContainer={true}
							cartId={cartId}/>
					</span>

					<span className="cart-checkout-lane-two">
						<StickySidebar>
							<>
								<CartOverview
									cartProducts={cartProducts ?? []}
									cartPricing={cartPricing}
									shouldShowShipping={hasShippingOptionSelected()}
								></CartOverview>
								<ValidationMessage
									isInvalid={validation.isActivelyInvalid()}
								/>
							</>
						</StickySidebar>
					</span>
					
				</span>
					
					<div className="cart-checkout-lane-one cart-checkout-proceed-container">
							<div className="cart-checkout-button-container">
								<PlaceOrderButton submitOrder={submitOrder}
								                  getPlaceOrderButtonText={getPlaceOrderButtonText}
								                  disabled={debouncedIsPlaceOrderButtonDisabled || validation.isActivelyInvalid()}/>
							</div>
							<div className="cart-checkout-payment-disclaimer">
								{t(`cart.paymentDisclaimer`)}
							</div>
						</div>

			</Container>
		</span>
	)
}
