import {CalculatedConfigurationLabels, Configuration, ConfigurationKeys} from '../configuration/Configuration';
import './NumericInput.css';
import {FormFeedback, Input, Label} from 'reactstrap';
import {InputClassification, ProductInputValue} from '../ProductInputValue';
import {FieldModifier} from "../configuration/FieldModifier";
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {useAppSelector, useConfigurationRules} from "../../../app/hooks";
import {RuleTypes} from "../configuration/rule/ConfigurationRule";
import React, {useEffect, useState} from "react";
import {getConfigurationIsSecureClass} from "../../../utils/Utils";
import {autoCompleteResolver} from "../../../utils/AutoCompleteResolver";
import {isUsingAdminSite} from "../../../app/apiSlice";
import {SiteProductVariantQuantityOption} from "../../product/SiteProductVariantQuantityOption";
import {selectAllInputValues} from "../ProductInputSlice";
import {OnValueChangeParams} from "../view/ProductInputView";

interface NumericInputProps {
    configuration?: Configuration,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    onValueChange: (params: OnValueChangeParams) => void,
    validateValue: (value?: ProductInputValue) => void,
    checkInput: boolean,
    productVariantId?: number,
    selectedQuantityOption?: SiteProductVariantQuantityOption
}

export const NumericInput = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers, onValueChange, validateValue, checkInput = false, productVariantId, selectedQuantityOption }: NumericInputProps) => {
    const inputId = configuration?.id ?? -1;
    const inputLabel = configuration?.configurationDetail.label ?? "";
    const isRequired = configuration?.configurationDetail.isRequired;
    const rulesState = useConfigurationRules(configuration?.rules, productInputValue?.value, productVariantId, [RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch]);
    const regexValue = configuration?.regexValue;
    const defaultNumericRegex = new RegExp("[^0-9]", "ig");
    const [ inputValue, setInputValue ] = useState(productInputValue?.value ?? '');
    const isAdminSite = isUsingAdminSite();
    
    useEffect(() => {
        if (productInputValue && productInputValue.value && productInputValue.value !== inputValue) {
            setInputValue(productInputValue.value);
        }
        // On initial load validate value
        if(configuration?.shouldValidateValue && productInputValue && productInputValue?.isValidated === undefined) {
            validateIfRulesPass(rulesState.inputIsInvalid, productInputValue);
        }
    }, [productInputValue]);

    function validateIfRulesPass(inputIsInvalid: boolean, value?: ProductInputValue) {
        if (!inputIsInvalid) validateValue(value);
    }

    function getEditModeTemplate() {
        return (
            <span className="numeric-input-edit-mode-container">
                <Input
                    className={getInputClasses()}
                    id={`numeric-input-${inputId}`}
                    type="text"
                    inputMode='numeric'
                    value={inputValue}
                    pattern="\d*"
                    onChange={(e) => onInputChange(e.target.value)}
                    required={isRequired}
                    autoComplete={autoCompleteResolver(configuration, isAdminSite || !!productInputValue?.value)}
                    invalid={rulesState.inputIsInvalid}
                ></Input>
                <FormFeedback tooltip>
                    {getInvalidMessage()}
                </FormFeedback>
            </span>
        );
    }

    function getInputClasses() {
        let classNames = getConfigurationIsSecureClass(configuration);
        let displayRequired = checkInput && isRequired && inputValue === '';

        if (displayRequired || rulesState.inputIsInvalid || (configuration?.shouldValidateValue && productInputValue?.isValidated === false)) {
            classNames += "invalid-text-input";
        }

        return classNames;
    }

    function getInvalidMessage() {
        if (rulesState.inputIsInvalid) return rulesState.brokenRuleMessage;

        return "";
    }

    function getReadModeTemplate() {
        return (
            <span className={`text-input-read-only-value ${getConfigurationIsSecureClass(configuration)}`}>
                {productInputValue?.value}
            </span>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getClassesForLabel() {
        if (!isInEditMode) {
            return "half-width-text-input";
        }
        return "";
    }

    function onInputChange(numericInput: string) {
        const regExpToUse = regexValue ? new RegExp(regexValue, "ig") : defaultNumericRegex;
        let returnNumericInput = numericInput.replace(regExpToUse, "");
        setInputValue(returnNumericInput);
        onValueChange({ inputValue: returnNumericInput });
    }

    function getCalculatedDisplayField() {
        if (isInEditMode || !productInputValue?.value) {
            return <></>
        }

        if (configuration?.configurationKey === ConfigurationKeys.CheckStartNumber && selectedQuantityOption
            && selectedQuantityOption?.checksPerPad && selectedQuantityOption?.padsPerUnit) {

            return (
                <span className={"input-text-container " + getClassesForModifiers(configuration?.fieldModifiers)}>
                    <Label  className={`numeric-input-label ${getClassesForLabel()}`}>
                        {CalculatedConfigurationLabels.CheckEndNumber.toString()}
                    </Label>
                    <span className={`text-input-read-only-value ${getConfigurationIsSecureClass(configuration)}`}>
                        {Number.parseInt(productInputValue.value) +
                            (selectedQuantityOption.checksPerPad * selectedQuantityOption.padsPerUnit)}
                    </span>
                </span>
            )
        }

        return <></>
    }

    // If in read only mode and there's no value, then show nothing
    if (!isInEditMode && !productInputValue?.value) {
        return <></>
    }

    return (
        <>
            <span className={"input-text-container " + getClassesForModifiers(configuration?.fieldModifiers)}>

                <Label for={`numeric-input-${inputId}`} className={`numeric-input-label ${getClassesForLabel()}`}>
                    {inputLabel}
                    {isRequired && isInEditMode ? <RequiredAsterisk></RequiredAsterisk> : <></>}
                </Label>
                {getValueRenderTemplate()}
            </span>
            {getCalculatedDisplayField()}
        </>
    );
}
