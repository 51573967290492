import { useFetchShippingOptionsQuery } from "../../app/apiSlice";
import {Card, CardBody, CardHeader, Input, Label, Spinner} from "reactstrap";
import React, {useCallback, useEffect, useState} from "react";
import {CurrencyFormatter} from "../../utils/CurrencyFormatter";
import {Address} from "../input/address/Address";
import {ShippingOptionsRequest} from "./ShippingOptionsRequest";
import {ShippingOption} from "./ShippingOption";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {debounce} from "lodash";
import "./ShippingAndHandlingSelector.css";
import {ShippingDisclaimer} from "./disclaimer/ShippingDisclaimer";
import {CartIdType} from "../cart/CartSlice";
import {useTranslation} from "react-i18next";
import {isAddressEmpty} from "../../utils/Utils";

interface ShippingAndHandlingSelectorProps {
    value?: number,
    toAddress?: Address,
    cartId?: CartIdType,
    orderId?: number,
    consumerId?: number,
    onChange: (shippingOption?: ShippingOption) => void,
}

export const ShippingAndHandlingSelector = ({value, toAddress, onChange, cartId, orderId, consumerId}: ShippingAndHandlingSelectorProps) => {
    const { t } = useTranslation();
    const [shippingOptionsRequest, setShippingOptionsRequest] = useState<ShippingOptionsRequest | undefined>(undefined);
    const { data: options, isFetching, error: optionsError} = useFetchShippingOptionsQuery(shippingOptionsRequest ?? skipToken);
    const [shippingOptions, setShippingOptions] = useState<ShippingOption[] | undefined>(undefined);
    const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceShippingOptions = useCallback(debounce(updateShippingOptionsRequest, 2000), [orderId, cartId]);

    function updateShippingOptionsRequest(toAddress: Address) {
        setShippingOptionsRequest({cartId, orderId, toAddress, consumerId, timestamp: Date.now()});
        setIsDebouncing(false);
    }

    useEffect(() => {
        if(shippingOptionsRequest && !shippingOptions) {
            const sortedOptions = options ?
                [...options].sort((a, b) => a.price - b.price || a.description.localeCompare(b.description)) : undefined;
            setShippingOptions(sortedOptions);
        }

        if (!value && shippingOptions && shippingOptions?.length > 0) {
            onChange(shippingOptions.find(so => so.isSelected) ?? shippingOptions.find(so => so.isDefault) ?? shippingOptions[0]);
        }
        else if (value && (!shippingOptions || shippingOptions.length === 0)) {
            onChange(undefined);
        }
    }, [value, options, shippingOptionsRequest, shippingOptions]);

    // update the shipping options request when toAddress changes, but make sure that we have all parts of the address & cartId or orderId
    useEffect(() => {
        if (toAddress?.street && toAddress?.city && toAddress?.zip && toAddress?.stateCode && (cartId !== undefined || orderId !== undefined)) {
            setShippingOptions(undefined);
            setShippingOptionsRequest(undefined);
            setIsDebouncing(true);
            debounceShippingOptions(toAddress);
        } else {
            setShippingOptionsRequest(undefined);
            setShippingOptions(undefined);
        }
    }, [cartId, orderId, toAddress, setShippingOptionsRequest, setShippingOptions]);

    const getShippingOptionById = (shippingOptionId?: number) => {
        const shippingOptionIndex = shippingOptions?.findIndex(so => so.id === shippingOptionId);
        return shippingOptionIndex !== undefined && shippingOptionIndex > -1 && shippingOptions && shippingOptions[shippingOptionIndex]
            ? shippingOptions[shippingOptionIndex] : undefined;
    }

    const getLoadingSpinner = () => {
        return (
            <Spinner size="sm">
                Loading...
            </Spinner>
        );
    }

    const getNoOptionsOption = () => {
        const errorText = optionsError && (toAddress?.street?.toLowerCase()?.includes('po box') ?? false )
            ? t('shipping.noShippingOptionsPoBox') : t('shipping.noShippingOptions');
        const noOptionText = isAddressEmpty(toAddress) ? t('shipping.provideShippingAddress') : errorText;
        return (
            <div className="shipping-and-handling-selector-no-options-label">{noOptionText}</div>
        )
    }


    const isOptionSelected = (shippingOptionId: number) => {
        return shippingOptionId === value;
    }

    return (
        <Card>
            <CardHeader className='d-flex justify-content-between card-header'>
                <h5 className="m-0">Shipping & Handling Method {isFetching || isDebouncing ? getLoadingSpinner() : ''}</h5>
            </CardHeader>
            <CardBody>
                {!isFetching && shippingOptions?.map(so => (
                    <div className="shipping-and-handling-selector-input-container" key={`shipping-and-handling-option-${so.id}`}>
                        <Input
                            name="shipping-and-handling-selector-input"
                            id={`shipping-and-handling-${so.id}`}
                            type="radio"
                            value={so.id}
                            checked={isOptionSelected(so.id)}
                            className="radio-option-input"
                            onChange={e => onChange(getShippingOptionById(Number.parseInt(e.target.value)))}
                        />
                        <Label check for={`shipping-and-handling-${so.id}`}>
                            {CurrencyFormatter.format(so.retailPrice)} {so.key}
                        </Label>
                    </div>
                ))}
                {!isFetching && !isDebouncing && (!options || options.length === 0) ? getNoOptionsOption() : <ShippingDisclaimer/>}

                </CardBody>
        </Card>
    );
}