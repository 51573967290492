import {useAppSelector, useSmartyStreets} from "../../../app/hooks";
import { selectCurrentConsumerUser } from "../../user/login/AuthenticationSlice";
import { isUsingAdminSite, useGetAddressesByConsumerIdQuery } from "../../../app/apiSlice";
import { Address, DefaultAddressType } from "./Address";
import { FormGroup, Input, Label } from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import {isAddressEmpty} from "../../../utils/Utils";
import {AddressInputVm} from "./Address";

interface SavedAddressSelectProps {
    selectedAddressId?: number,
    onAddressSelected: (address?: Address) => void,
    address?: AddressInputVm,
    shouldUseDefault: boolean,
    consumerId?: number, // uses signed in consumer by default if not provided
    defaultAddress?: DefaultAddressType,
    className?: string,
    locked?: boolean
}
export const SavedAddressSelect = ({ selectedAddressId, onAddressSelected, address, shouldUseDefault, consumerId, defaultAddress, className, locked }: SavedAddressSelectProps) => {
    const signedInConsumerId = useAppSelector(selectCurrentConsumerUser)?.id;
    // don't use signedInConsumer if on admin site
    const { data: addresses } = useGetAddressesByConsumerIdQuery(isUsingAdminSite() && !consumerId ? skipToken : consumerId ?? signedInConsumerId ?? skipToken);
    const [addressId, setAddressId] = useState(selectedAddressId ?? -1);
    const { isVerified } = useSmartyStreets(address ?? {});

    //runs whenever isVerified changes to set verified = true on the address object if the address is verified
    useEffect(() => {
        const selectedAddress = addresses?.find(a => a.id === addressId)
        if (isVerified && selectedAddress)
            setAddress({...selectedAddress, isVerified});
    }, [isVerified, addressId])

    //whenever a new addressId is selected, we set the address to that and set its isVerified to false
    useEffect(()=>{
        const selectedAddress = addresses?.find(a => a.id === addressId)
        if(selectedAddress)
            setAddress({...selectedAddress, isVerified: false});
    }, [addressId, addresses])

    //lets parent set the address select to "new address" (ex: fill from check)
    useEffect(()=>{
        if (!selectedAddressId || selectedAddressId === -1)
            setAddressId(-1)
    }, [selectedAddressId])

    const setAddress = useCallback((address: AddressInputVm) => {
        onAddressSelected(address);
    }, [onAddressSelected]);

    const onSelectClick = (addressId?: number) => {
        if( !addressId || addressId === -1 ){
            setAddressId(-1)
            setAddress({})
        }else{
            setAddressId(addressId)
        }
    }
    
    // find default address on addresses load
    useEffect(() => {
        const defaultShippingAddress = addresses?.find(a => a.isDefaultShippingAddress);
        const defaultBillingAddress = addresses?.find(a => a.isDefaultBillingAddress);
        if (defaultAddress === undefined || !addresses?.length || !isAddressEmpty(address) || !shouldUseDefault)
            return;
        if (defaultAddress === DefaultAddressType.Shipping && defaultShippingAddress?.id) {
            setAddressId(defaultShippingAddress.id);
        }
        else if (defaultAddress === DefaultAddressType.Billing && defaultBillingAddress?.id) {
            setAddressId(defaultBillingAddress.id);
        }

    }, [addresses, defaultAddress]);
    
    
    if (!addresses?.length) {
        return null;
    }
    
    return (
        <FormGroup className={className}>
            <Label for='SavedAddress'>Saved Address</Label>
            <Input type='select'
                   id='SavedAddress'
                   name='id'
                   disabled={locked ?? false}
                   value={addressId}
                   onChange={e => onSelectClick(e.target.value ? Number.parseInt(e.target.value) : -1)}
            >
                <option value={-1}>New Address</option>
                { addresses?.map(a => <option key={a.id} value={a.id}>{a.street}, {a.city}, {a.stateCode}, {a.zip}</option>) }
            </Input>
        </FormGroup>
    );
}