import {useNavigate, useParams} from "react-router-dom";
import {
    useGetChildSiteCategoriesQuery, useGetSiteCategoryByUrlNameOrIdQuery,
} from "../../../app/apiSlice";
import {CategoryHeader} from "../header/CategoryHeader";
import {Container} from "reactstrap";
import {CategoryBrowser} from "../browser/CategoryBrowser";
import ScrollToTop from '../../helpers/ScrollToTop';
import {skipToken} from "@reduxjs/toolkit/query";
import styles from './CategoryView.module.css';
import {Helmet} from "react-helmet-async";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

export const CategoryView = () => {
    let { siteCategoryName } = useParams();
    const { data: siteCategory, isFetching: isFetchingSiteCategory, isError: errorLoadingSiteCategory } = useGetSiteCategoryByUrlNameOrIdQuery(siteCategoryName!);
    const { data: childCategories } = useGetChildSiteCategoriesQuery(siteCategory?.id ?? skipToken);
    const navigate = useNavigate();

    if (!siteCategory) {
        // Loading category
        if (isFetchingSiteCategory) return <LoadingSpinner></LoadingSpinner>;
        // Redirect home if not a valid category name
        if (!isFetchingSiteCategory && errorLoadingSiteCategory) navigate("/");

        return <></>;
    }
    
    function getDefaultDocumentMetadata () {
        if (siteCategory) {
            return (
                <Helmet>
                  <title>{siteCategory.metaTitle}</title>
                  <meta name="description" content={siteCategory.metaDescription} />
                </Helmet>
            )
        }
      }
  
    return (
        <span className={styles.categoryViewContainer}>
            <ScrollToTop></ScrollToTop>
            {getDefaultDocumentMetadata()}
            <CategoryHeader
                siteCategory={siteCategory}
                bubbleSiteCategories={childCategories?.filter(cc => cc.showBubble) ?? []}
            ></CategoryHeader>
            <Container className={styles.categoryItemsContainer}>
                { childCategories &&
                    <CategoryBrowser siteCategories={childCategories?.length ? childCategories : [siteCategory]} contextualSiteCategory={siteCategory}/>
                }
                { siteCategory.bottomMessage &&
                    <div className={styles.categoryFlavorText}>{siteCategory.bottomMessage}</div>
                }
            </Container>
        </span>
    )
}
