import { Input } from "reactstrap"
import { EmailInput } from "../../../input/email/EmailInput"
import { ControlListValueLabel, ControlListValueType } from "../ControlListGroup"
import { Address, AddressInputVm } from "../../../input/address/Address"
import { ValidatedAddressInput } from "../../../input/validatedAddress/ValidatedAddressInput"
import { useMemo } from "react"
import { Regexes, getPrettyAddress } from "../../../../utils/Utils"
import { RegexValidatedInput } from "../../../input/regex/RegexValidatedInput"

interface ControlListStringValueInputProps {
    id: string
    label: ControlListValueLabel // not used for display, but rather to figure out information about the input we're displaying
    value: string
    onValueChange: (value: string) => void
    options?: string[]
    addressOptions?: Address[]
    valueType: ControlListValueType
    disabled?: boolean
    invalid?: boolean
    clear?: boolean
    suppressError?: boolean
}
export const ControlListStringValueInput = ({ id, label, value, onValueChange, options, addressOptions, valueType, disabled, invalid, clear, suppressError }: ControlListStringValueInputProps) => {
    const addressValue = useMemo(() => {
        if (valueType !== ControlListValueType.Address) return {};
        try {
            const addr = JSON.parse(value) as AddressInputVm;
            return { street: addr.street, city: addr.city, stateCode: addr.stateCode, zip: addr.zip };
        } catch {
            return {};
        }
    }, [value, valueType]);

    const bankInfoRegex = label === ControlListValueLabel.Aba ? Regexes.aba : Regexes.accountNumber;
    const bankInfoInvalidMessage = label === ControlListValueLabel.Aba ?
        'Valid ABA is required. Must be 9 numbers.' :
        'Valid account number required. Must be less than 15 digits.';

    // None, skip
    if (valueType === ControlListValueType.None) {
        return null;
    }
    
    // ValidatedAddressInput
    if (valueType === ControlListValueType.Address && !addressOptions?.length) {
        return <ValidatedAddressInput value={addressValue}
                                      onChange={addr => onValueChange(JSON.stringify({street: addr?.street, city: addr?.city, stateCode: addr?.stateCode, zip: addr?.zip}))}
                                      suppressError={suppressError}
                                      clear={clear}
                                      required />
    }

    // EmailInput
    if (label === ControlListValueLabel.Email && !options?.length) {
        return <EmailInput id={id}
                           onChange={e => onValueChange(e.target.value)}
                           value={value}
                           disabled={disabled}
                           required
                           clear={clear}
                           suppressError={suppressError}/>
    }
    
    // Aba/Account Number Input (validated by regex)
    if ([ControlListValueLabel.Aba, ControlListValueLabel.AccountNumber].includes(label)) {
        return <RegexValidatedInput id={id}
                                    regex={bankInfoRegex}
                                    onChange={e => onValueChange(e.target.value)}
                                    value={value}
                                    disabled={disabled}
                                    required
                                    clear={clear}
                                    invalid={invalid}
                                    suppressError={suppressError}
                                    invalidMessage={bankInfoInvalidMessage}/>
    }
    
    // select for stringvalue options
    if (options?.length) {
        return (
            <Input id={id} type='select' onChange={e => onValueChange(e.target.value)} value={value} disabled={disabled} required invalid={invalid}>
                {options.map(o => <option key={o} value={o}>{o}</option>)}
            </Input>
        );
    }

    // select for address value options
    if (addressOptions?.length && valueType === ControlListValueType.Address) {
        return (
            <Input id={id} type='select' onChange={e => onValueChange(e.target.value)} value={JSON.stringify(addressValue)} disabled={disabled} required invalid={invalid}>
                {addressOptions.map(ao =>
                    <option key={`${ao.street}-${ao.city}-${ao.stateCode}-${ao.zip}`} value={JSON.stringify({ street: ao.street, city: ao.city, stateCode: ao.stateCode, zip: ao.zip})}>
                        {getPrettyAddress(ao)}
                    </option>
                )}
            </Input>
        )
    }
    
    // regular StringInput
    return (
        <Input id={id}
               type={(options?.length || addressOptions?.length) ? 'select' : 'text'}
               onChange={e => onValueChange(e.target.value)}
               value={valueType === ControlListValueType.Address ? JSON.stringify(addressValue) : value}
               disabled={disabled}
               required
               invalid={invalid}/>
    );
}