import {OrderMonthEnd} from "./OrderMonthEnd";
import {ColumnConfig} from "../../admin/shared/Grid";
import {reportCol, reportDateToString, reportMoneyToString, reportPipeDelimitedList, reportValToOrderLink, ReportView} from "../ReportView";
import {useGetOrderMonthEndReportQuery} from "../../../app/apiSlice";

export const OrderMonthEndReport = () => {
  const filterFunc = (lowerSearch: string, records: OrderMonthEnd[]) =>
    records.filter(r =>
      r.orderNumber.toString().includes(lowerSearch) ||
      r.orderStatus.toLowerCase().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.paymentMethod.toLowerCase().includes(lowerSearch) ||
      r.paymentStatus.toLowerCase().includes(lowerSearch) ||
      r.shippingMethod.toLowerCase().includes(lowerSearch) ||
      r.refundTransactionId.toLowerCase().includes(lowerSearch) ||
      r.shippingCity.toLowerCase().includes(lowerSearch) ||
      r.shippingState.toLowerCase().includes(lowerSearch) ||
      r.shippingZipCode.toLowerCase().includes(lowerSearch)
    );
  
  const columns: ColumnConfig<OrderMonthEnd>[] = [
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('brand', 'Brand'),
    reportCol('source', 'Source'),
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('orderStatus', 'Status'),
    reportCol('dateCompleted', 'Date Completed', reportDateToString),
    reportCol('paymentMethod', 'Payment Meth.'),
    reportCol('paymentStatus', 'Payment Status'),
    reportCol('shippingMethod', 'Shipping Meth.'),
    reportCol('orderNetTotal', 'Order Net', reportMoneyToString),
    reportCol('productPrice', 'Product Price', reportMoneyToString),
    reportCol('productAdjustment', 'Product Adj.', reportMoneyToString),
    reportCol('shippingPrice', 'Shipping Price', reportMoneyToString),
    reportCol('shippingAdjustment', 'Shipping Adj.', reportMoneyToString),
    reportCol('ezShieldPrice', 'EZ Shield', reportMoneyToString),
    reportCol('fontPrice', 'Font', reportMoneyToString),
    reportCol('woodCutPrice', 'WoodCut', reportMoneyToString),
    reportCol('customMessagePrice', 'Custom Mesg.', reportMoneyToString),
    reportCol('extraSigLinePrice', 'Extra Sig. Line', reportMoneyToString),
    reportCol('discountAmount', 'Discount', reportMoneyToString),
    reportCol('refundIssued', 'Refund', reportMoneyToString),
    reportCol('refundTransactionId', 'Refund Trans. ID', reportPipeDelimitedList),
    reportCol('handling', 'Handling', reportMoneyToString),
    reportCol('tax', 'Tax', reportMoneyToString),
    reportCol('retailDeliveryFee', 'Retail Delivery Fee', reportMoneyToString),
    reportCol('shippingCost', 'Ship Cost', reportMoneyToString),
    reportCol('shippingCity', 'Ship City'),
    reportCol('shippingState', 'Ship State'),
    reportCol('shippingZipCode', 'Ship Zip'),
  ];

  return <ReportView
    title='Order Download' // name change
    query={useGetOrderMonthEndReportQuery}
    columns={columns}
    initialSort={{ column: 'orderDate', direction: 'asc'}}
    filterFunc={filterFunc}
    downloadPath='/api/Reports/OrderMonthEnd'
    downloadName='Order Download'/>
}